/**
 * Group Specific Banner
 * -----------------------------------------------------------------------------
 */
import { useTranslation } from "react-i18next"
import React from "react"
import http from "utils/http"
import { Alert, Shimmer, Markdown, Button } from "@/elements"

export interface Props {
    readonly alertId: string,
    readonly body?: string,
    readonly hasIcon: boolean,
    readonly header?: string,
    readonly id: string,
    readonly isDismissable?: boolean,
    readonly isDismissed?: boolean,
    readonly isValid?: boolean,
    readonly level?: "default" | "error" | "success" | "info" | "warning"
    readonly type?: "per session" | "per user"
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const GroupSpecificBanner = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Component state
     */
    const [banner, setBanner] = React.useState<Props>(undefined)
    const [bannerLoading, setBannerLoading] = React.useState(false)
    /**
     * Fetch alert banner from styx
     */
    const getAlertBanner = () => {
        setBannerLoading(true)
        const bannerId = "f8YbGNkSJATFAkcgz2BSTD"
        const config = { params: { id: bannerId } }
        http.get("/api/managed-content-blocks", config).then(({ data }) => {
            setBanner(data[0]?.data)
            setBannerLoading(false)
        })
    }

    const createButton = () => {
        if (!banner) return

        // get button URL from first link found in banner body
        const regexURL = /href="(http[s]?:\/\/.+?)"/mi;
        const matches = banner.body.match(regexURL);
        const buttonURL = matches.length > 1 ? matches[1] : null;

        if (!buttonURL) return

        // eslint-disable-next-line functional/no-let
        let buttonText = ""
        // eslint-disable-next-line functional/no-let
        const headerText = banner.header

        if (headerText.includes("Zenith")) {
            buttonText = t("jointAdmin.gotoSite", { site: "Zenith" })
        } else if (headerText.includes("AmeriBen")) {
            buttonText = t("jointAdmin.gotoSite", { site: "AmeriBen" })
        } else if (headerText.includes("MagnaCare")) {
            buttonText = t("jointAdmin.gotoSite", { site: "MagnaCare" })
        } else if (headerText.includes("Carpenter")) {
            buttonText = t("jointAdmin.gotoSite", { site: "carpentersbenefits.org" })
        }
        return (
            <Button
                name="secondary-button"
                label={buttonText}
                variant="secondary"
                onClick={() => window.open(buttonURL, "_blank")}
                className="md:m-xxs py-xxs border-none font-bold"
            />
        );
    }

    React.useEffect(() => {
        getAlertBanner()
    }, [])

    return (
        <div className="mb-xs">
            <div className="flex flex-row">
                {bannerLoading && (<Alert
                    name={"Group Specific Banner"}
                    className="mb-xs w-full"
                    variant={"warning"}
                    hideIcon={true}
                    onDismiss={undefined}
                >
                    <div className="flex flex-col">
                        <div className="mb-xxs">
                            <Shimmer placeholder="Loading a group specific banner.  Please wait..." />
                        </div>
                        <div className="mb-xxs">
                            <Shimmer placeholder="Loading a group specific banner.  Please wait..." />
                        </div>
                    </div>
                </Alert>)}
                {bannerLoading === false && banner && banner.body && (<Alert
                    name={"Group Specific Banner"}
                    className="mb-xs w-full"
                    variant={"warning"}
                    hideIcon={false}
                    onDismiss={undefined}
                    childrenClassName="w-full"
                >
                    <div className="flex flex-col md:flex-row justify-between w-full">
                        <div>
                            {banner.header && <h6>{banner.header}</h6>}
                            {banner.body &&
                                <Markdown
                                    content={banner.body}
                                    config={{ ADD_ATTR: ["target"] }}
                                    linkNameString="joint-admin-group-banner-"
                                />}
                        </div>
                        <div className="md:flex-right md:ml-sm -mr-xxxs">
                            {banner.body && createButton()}
                        </div>
                    </div>
                </Alert>
                )}
            </div>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default GroupSpecificBanner
