import BrandedImage from "@/elements/branded_image"
import { useFeatures } from "store"
import { tagEvent } from "@/utils/analytics"
import Features from "@/utils/features"
import { Button, useModalStore, Modal } from "heartwood-component-library"
import { Spotlight } from "heartwood-component-library/nextjs"
import React from "react"
import { http } from "@/utils"
import { CallBackProps } from "react-joyride"
import { useTranslation } from "react-i18next"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly claimNavigation: boolean
    readonly pharmacyNavigation: boolean
    readonly careNavigation: boolean
}

const NavigationSpotlight: React.FC<Props> = (props: Props) => {

    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Feature booleans
    */
    const {
        [Features.DASHBOARD_SPOTLIGHT]: isDashboardSpotlight,
        [Features.NAVIGATION_SPOTLIGHT]: isNavigationSpotlight,
    } = useFeatures()

    const [visible, setVisible] = React.useState(false)
    const modal = useModalStore({ open: visible })
    const [start, setStart] = React.useState(false)
    const showSpotlight = (!isDashboardSpotlight && isNavigationSpotlight)
    const isNavigationLayout = (window.innerWidth >= 1140)

    React.useEffect(() => {
        const isDismissed = (window.sessionStorage.getItem("navigation_dismissed") == "true")
        if (showSpotlight && isNavigationLayout && !isDismissed) {
            setVisible(true)
            window.sessionStorage.setItem("navigation_dismissed", "true")
        }
    }, [])

    // Window Resize to hide Spotlight
    const handleWindowSizeChange = () => {
        if (window.innerWidth >= 1140) return
        setVisible(false)
        setStart(false)
    }
    React.useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [])

    /**
     * Add/remove `overflow-y-scroll` parent class for Spotlight positioning
     */
    const toggleParentOverflow = (reset?: boolean) => {
        const parentElement = document.getElementById("nav-items-wrap")
        const className = "overflow-y-auto"
        if (reset) {
            parentElement.classList.add(className)
        } else {
            parentElement.classList.remove(className)
        }
    }

    const sendSpotlightDismiss = async () => {
        toggleParentOverflow(true)
        // Ivan's EMS method instead of dispatch(actions.spotlightDismiss())
        const { error: errorNav, status: statusNav, data: dataNav } = await http.put(`/api/ems/feature-flags/user-signals/dismiss/navigation_spotlight`, {})
        if(errorNav || statusNav >= 400){
            // cannot use janusLogger in a browser environment
            console.error("Failed to dismiss navigation spotlight", statusNav, errorNav)
        }
    }

    const onClick = () => {
        toggleParentOverflow(false)
        setVisible(false)
        setStart(true)
        tagEvent({
            tealium_event: "link",
            data_analytics_id: "navigation-spotlight-click",
            link_text: "See what's changed",
            link_url: "",
        })
    }

    // reset the overlay style
    const resetOverlaytStyle = () => {
        const joyride_spotlight = document.getElementsByClassName("react-joyride__spotlight")
        if (!joyride_spotlight || !joyride_spotlight[0]) { return }

        const spotlight = joyride_spotlight[0] as HTMLBaseElement
        if (spotlight) {
            spotlight.style.borderRadius = "0px"
            spotlight.style.left = "-20px"
        }
    }

    const onSpotlight = (state: CallBackProps) => {
        // reset the overlay style
        resetOverlaytStyle()

        // start
        if (state.action == "start" && state.lifecycle == "ready" ) {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-start",
                link_text: "start",
                link_url: "",
            })
        }
        // next
        else if (state.action == "next" && state.lifecycle == "ready" && state.type !== "tour:end") {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-next",
                link_text: "next",
                link_url: "",
            })
        }
        // back
        else if (state.action == "prev" && state.lifecycle == "ready" ) {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-back",
                link_text: "back",
                link_url: "",
            })
        }
        // escape next
        else if (state.action == "close" && state.lifecycle == "ready") {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-escape-next",
                link_text: "esc",
                link_url: "",
            })
        }
        // escape close
        else if (state.action == "close" && state.lifecycle == "complete") {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-escape-complete",
                link_text: "esc",
                link_url: "",
            })
        }
        // Close X
        else if (state.action == "skip" && state.status == "skipped" && state.type == "tour:end") {
            sendSpotlightDismiss()
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-close",
                link_text: "Close",
                link_url: "",
            })
        }
        // Got It
        else if (state.action == "next" && state.status == "finished" && state.type == "tour:end") {
            sendSpotlightDismiss()
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "navigation-spotlight-got-it",
                link_text: "Got It",
                link_url: "",
            })
        }
    }

    const onDismiss = () => {
        setVisible(false)
        sendSpotlightDismiss()
        tagEvent({
            tealium_event: "link",
            data_analytics_id: "navigation-spotlight-dismiss",
            link_text: "Dismiss",
            link_url: "",
        })
    }

    /**
     * KeyDown Events
     */
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setVisible(false)
        }
    }

    /**
     * Define template variables
     */
    const strings = t("dashboard.navigationSpotlight", { returnObjects: true })

    /**
     * Conditional Data Steps
     */
    const spotlightSteps=(claimNavigation: boolean, pharmacyNavigation: boolean, careNavigation: boolean) => {
        const dataSteps = []

        if (claimNavigation) {
            dataSteps.push(
                {
                    target: "#claims-link",
                    title: strings.claimNavigation.title,
                    content: strings.claimNavigation.content,
                    position: "right",
                }
            )
        }
        if (pharmacyNavigation) {
            dataSteps.push(
                {
                    target: "#pharmacy-link",
                    title: strings.pharmacyNavigation.title,
                    content: strings.pharmacyNavigation.content,
                    position: "right",
                }
            )
        }
        if (careNavigation) {
            dataSteps.push(
                {
                    target: "#care-link",
                    title: strings.careNavigation.title,
                    content: strings.careNavigation.content,
                    position: "right",
                }
            )
        }
        return dataSteps
    }
    const steps = spotlightSteps(props.claimNavigation, props.pharmacyNavigation, props.careNavigation)

    /**
     * Paradise Template
     */
    // style="border-radius: 16px; position: fixed; background-color: gray; height: 76px; left: -10px; opacity: 1; pointer-events: auto; top: 334px; transition: opacity 0.2s ease 0s; width: 260px;"
    return (
        <div onKeyDown={handleKeyDown}>
            <Spotlight
                data-test="navigation-spotlight"
                name="navigation-spotlight"
                start={start}
                steps={steps}
                joyride={{
                    scrollOffset: 160,
                    disableCloseOnEsc: true,
                    disableOverlayClose: true,
                    callback: state => {onSpotlight(state)},
                    disableScrolling: true,
                }}
            />

            <Modal
                data-test="navigation-spotlight-modal"
                name="navigation-spotlight-modal"
                store={modal}
                title={strings.modal.title}
                hideTitle={true}
                size="medium"
                autoFocus={false}
            >
                <Modal.Content className="bg-primary-50 -mx-sm -mt-sm rounded-t-lg !px-0 !py-0 overflow-hidden">
                    <BrandedImage
                        regence="regence_navigation_spotlight"
                        asuris="asuris_navigation_spotlight"
                        bridgespan="bridgespan_navigation_spotlight"
                        alt="What's new"
                        className="object-cover h-full w-full"
                    />
                </Modal.Content>
                <Modal.Content className="pt-sm px-0 pb-0 md:px-xxs">
                    <h5 className="py-0">{strings.modal.content.title}</h5>
                    <p className="text-sm sm:text-md mb-2 min-[300px]:mb-4">
                        {strings.modal.content.body}
                    </p>
                    <div className="hidden smx:block">
                        <div className="flex justify-end mb-1 px-0 py-0">
                            <Button
                                data-test="navigation-dismiss-button"
                                className="ml-xs text-sm sm:text-md"
                                name="dismiss-button"
                                label={strings.button.dismiss.label}
                                variant="link"
                                onClick={onDismiss}
                            />
                            <Button
                                data-test="navigation-confirm-button"
                                className="ml-xs text-sm sm:text-md"
                                name="confirm-button"
                                label={strings.button.confirm.label}
                                onClick={onClick}
                            />
                        </div>
                    </div>
                    <div className="block smx:hidden">
                        <div className="text-center mb-2 px-0 py-0">
                            <Button
                                data-test="navigation-confirm-button"
                                className="text-sm sm:text-md"
                                name="confirm-button"
                                label={strings.button.confirm.label}
                                onClick={onClick}
                            />
                        </div>
                        <div className="text-center mb-0 px-0 py-0">
                            <Button
                                data-test="navigation-dismiss-button"
                                className="text-sm sm:text-md"
                                name="dismiss-button"
                                label={strings.button.dismiss.label}
                                variant="link"
                                onClick={onDismiss}
                            />
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default NavigationSpotlight
