import { createSelector } from "@reduxjs/toolkit"
import { Medication } from "./types"

// const medicationsSelector = state => [...state.medications.items]
const medicationsSelector = state => state.medications?.items?.length ? [...state.medications.items] : []

// Sort the medications by latest fill/alphabetical and select latest 3
export const selectLatestThreeMedications = createSelector(
    medicationsSelector,
    (medications: readonly Medication[]) => {
        const orderedMedications = [...medications].sort((med1, med2) => {
            // If med1 occurs before med2 return 1
            if (med1.lastFillDate < med2.lastFillDate) {
                return 1
            }
            // If med1 occurs after med2 return -1
            if (med1.lastFillDate > med2.lastFillDate) {
                return -1
            }
            // Otherwise, fill dates are equal so sort by name alphabetically
            if (
                med1.displayName.toLowerCase() < med2.displayName.toLowerCase()
            ) {
                return -1
            }
            // Expect display names are different since medications should be unique
            return 1
        })
        // return latest 3 medications in alphabetical order
        return orderedMedications.slice(0, 3)
    },
)
