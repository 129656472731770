/**
 * Dashboard Resources
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions, useFeatures } from "store"
import ProgramCard from "components/programs/v2/program_card"
import EmptyState from "components/dashboard/empty_state"
import Features from "utils/features"
import { removeOrderedPrograms } from "store/programs/utils"
import { Program, Programs } from "store/programs/reducer"
import { Button, Card } from "heartwood-component-library"
import router from "next/router"
import Routes from "@/router"
import ShimmerCard from "./shimmer_card"
import { useTranslation } from "react-i18next"

const homeIds = [
    "DISPATCH_HEALTH",
    "ADVANTAGES_DISCOUNTS",
    "MEDICARE_DISCOUNTS_RESOURCES",
    "BEHAVIORAL_HEALTH",
    "EMPOWER",
    "ESTIMATE_COSTS",
    "PREVENTATIVE_CARE",
    "WA_SUBSTANCE_ABUSE", // only for WA users
    "TELEHEALTH",
    "GENDER_AFFIRMING_CARE", // when WA_SUBSTANCE_ABUSE absent
]

const resourceOrderBSH = [ 
    "DISPATCH_HEALTH",
    "ADVANTAGES_DISCOUNTS",
    "BEHAVIORAL_HEALTH",
    "ESTIMATE_COSTS",
    "GENDER_AFFIRMING_CARE",
    "WA_SUBSTANCE_ABUSE",
    "TELEHEALTH",
]

const FlagIds = [
    "DISPATCH_HEALTH",
]
const removalIds = [
    "ESTIMATE_COSTS",
]

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const DashboardResources = () => {
    /**
     * Feature flags
     */
    const {
        [Features.HCA_GENERAL]: hca,
        [Features.CIS]: cis,
        [Features.MEDIGAP]: isMedigap,
    } = useFeatures()

    /**
     * Hooks
     */
    const dispatch = useDispatch()
    const { t } = useTranslation()

    /**
     * Application state
     */
    const [programs, app] = useSelector(state => [state.programs, state.app])
    const brand = app.brand
    const isBSH = brand === "bridgespan"

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (programs.status === "pristine") {
            dispatch(actions.fetchPrograms())
        }
    }, [])

    const sortResources = (programs: ReadonlyArray<Program>, order: ReadonlyArray<string>): readonly Program[] => {
        return order.reduce((acc: readonly Program[], o: string) => {
            const program = programs.find(p => p.id === o);
            return program ? [...acc, program] : acc;
        }, []);
    }

    const bshPrograms = programs.items.filter(program => program.category.includes("BSH"))

    const updatedResourceOrderBSH = removeOrderedPrograms(
        bshPrograms,
        FlagIds,
        resourceOrderBSH,
        removalIds,
    )

    /**
     * Methods
     */
    const onClick = () => {
        router.push(Routes.resources())
    }

    // Overwriting method in store/programs/utils.ts because it removes Estimate Costs
    const defineSortedPrograms = (
        programs: Programs,
    ): ReadonlyArray<Program> => {
        const items = homeIds
            .map(id => programs.items?.find(program => program.id === id))
            .filter(program => !!program?.id)
        return items || []
    }
    
    /**
     * Define template variables
     */
    const isLoading = programs.status === "pending"
    const preventiveHealthItem = programs.items.find(program => program.id === "PREVENTATIVE_CARE")
    const hcaItems = [...programs.items.filter(program => program.category.includes("Hca")), preventiveHealthItem]
    const cisItems = [...programs.items.filter(program => program.category.includes("Cis")), preventiveHealthItem]
    const items = hca ? hcaItems :
        cis ? cisItems :
            isBSH && !isMedigap ? sortResources(bshPrograms, updatedResourceOrderBSH) :
                defineSortedPrograms(programs)

    const displayItems: readonly Program[] = items.filter(item => !["BEHAVIORAL_HEALTH", "TELEHEALTH", "EMPOWER", "DISPATCH_HEALTH"].includes(item?.id))
    const hasItems = !isLoading && !!displayItems.length
    const displayLimit = hca || cis ? 6 : 3
    const strings = t("dashboard.resources", { returnObjects: true })

    /**
     * Template
     */
    return (
        <Card name="dashboard-resources-card" radius="lg" data-test="resources"  className="my-lg !py-[32px]">
            <Card.Content className="-mx-xs">
                <div className="flex items-center justify-between mb-xs">
                    <h5 className="mb-none">Resources</h5>
                    {hasItems && (<strong>
                        <Button
                            name="see-resources-button"
                            label={strings.viewMoreButton.label}
                            variant="link"
                            onClick={() => onClick()}
                            className="px-xs pt-none"
                            data_analytics_id="see-resources-button"
                            data-analytics-event="dfwEvent"
                        />
                    </strong>
                    )}
                </div>

                {/* Loading state */}
                {isLoading && (
                    <React.Fragment>
                        <ShimmerCard/>
                        <ShimmerCard/>
                    </React.Fragment>
                )}

                {/* Program cards */}
                {displayItems.slice(0,displayLimit).map((item, index) => (
                    <div key={index} className="flex items-center py-xs">
                        <ProgramCard key={index} {...item} />
                    </div>
                ))}

                {/* Empty state */}
                {!hasItems && !isLoading && <EmptyState label={strings.emptyState.label} />}
            </Card.Content>
        </Card>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default DashboardResources
