/**
 * Advice 24 Dialog Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Button, Modal, Markdown } from "elements"
import { Pictogram } from "heartwood-component-library"
import { CTAAction } from "@/store/programs/reducer"
import Link from "../../elements/link"
import { useTranslation } from "react-i18next"
import Routes from "@/router"
import { actions, useDispatch } from "@/store"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly cta: {
        readonly title: string
        readonly labels?: ReadonlyArray<string>
        readonly description?: string
        readonly actions: ReadonlyArray<CTAAction>
    }
    readonly isVisible: boolean
    readonly onDismiss?: (e?: React.MouseEvent) => void
    readonly onClick?: (e?: React.MouseEvent) => void
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Advice24DialogModal: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const chatHandler = async () => {
        dispatch(actions.startPagerChat())
        props.onDismiss
    }

    /**
     * Define template variables
     */
    const hasChat = props.cta?.actions.find(action => action.type === "chat")

    /**
     * Template
     */
    return (
        <Modal
            name={props.name + "-advice-24-dialog-modal"}
            title={props.cta?.title}
            noBorder={true}
            isVisible={props.isVisible}
            onDismiss={props.onDismiss}
            size="large"
        >
            <Modal.Content>
            <div tabIndex={0}>
                <Markdown content={props.cta?.description} />
            </div>
                <div className="md:grid md:grid-cols-2 divide-y md:divide-y-none md:divide-x divide-gray-100 py-sm" >
                    {props.cta?.actions.map((action, id) => {
                        if (action.type === "call") {
                            return (
                                <div key={id} className="flex items-center mb-xs md:mb-none">
                                    <Pictogram
                                        alt=""
                                        regence="reg_nurseline_emerald"
                                        asuris="ahs_nurse_chat"
                                        bridgespan="bsh_contact_us"
                                        size="small"
                                    />

                                    <p 
                                        tabIndex={0}
                                        id={
                                            props.name +
                                            "-advice24-phone-number-block"
                                        }
                                        className="mb-none font-bold text-primary-200 md:pl-xs">
                                        {/* Functional link for mobile, non-functional link for desktop */}
                                        <Link
                                            name={
                                                props.name +
                                                "-advice24-phone-number-link"
                                            }
                                            href={action.url}
                                            target={"_self"}
                                            appearance="none"
                                            className="block md:hidden"
                                        >
                                            {t("advice24Dialog.call")}&nbsp;{action.title?.slice(5, 21)}
                                        </Link>
                                        <span className="hidden md:block">{action.title}</span>
                                    </p>

                                    {/* Show TTY as non link in mobile view if exist */}
                                    {action.title?.includes("TTY") && (
                                        <span className={"inline-block"}>
                                            &nbsp;
                                            {t("advice24Dialog.tty") +
                                                ": " +
                                                action.title.slice(27, 30)}
                                        </span>
                                    )}
                                </div>
                            )
                        }

                        if (action.type === "chat") {
                            return (
                                <div key={id} className="flex items-center pt-xs pl-none md:pt-none md:pl-lg">
                                    <Pictogram
                                        alt=""
                                        regence="reg_chat_emerald"
                                        asuris="ahs_live_chat_1"
                                        bridgespan="bsh_live_chat"
                                        size="small"
                                    />
                                    <div className="flex-1 flex-col mx-xs">
                                        <p
                                            id={
                                                props.name + "-chat-title-block"
                                            }
                                            className="mb-none font-bold text-primary-200"
                                        >
                                            {action.title}
                                        </p>
                                        <small
                                            id={
                                                props.name +
                                                "-chat-availability-block"
                                            }
                                            className="text-start text-gray-200 text-xs"
                                        >
                                            {action.availability}
                                        </small>

                                    </div>
                                    <div className="flex flex-col items-center">
                                        <Button
                                            name={
                                                props.name +
                                                "-advice24-chat-button"
                                            }
                                            label={action.label}
                                            variant="primary"
                                            size="small"
                                            onClick={props?.onClick || chatHandler}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </Modal.Content>

            {hasChat && (
                <Modal.Footer className="pt-sm">
                    <small>{t("advice24Dialog.chatDisclaimer")}&nbsp;</small>
                    <Link
                        name="terms-of-use-link"
                        href={Routes.memberAdviceThirdParty()}
                        target="_blank"
                    >
                        {t("advice24Dialog.learnMore")}
                    </Link>
                    .
                </Modal.Footer>
            )}

        </Modal >
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Advice24DialogModal
