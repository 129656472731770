/**
 * PaymentBanner
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Routes, { useRouter } from "router"
import { formatDate, formatCurrency } from "utils"
import { useTranslation } from "react-i18next"
import { useFeatures, useSelector, useDispatch, actions } from "@/store"
import Features from "@/utils/features"
import { Alert, Button, Icon, IconTypes, Shimmer } from "heartwood-component-library"
import { ExternalLinkModal } from "@/elements"
import ErrorDisplay from "@/components/error_display"
// eslint-disable-next-line functional/no-let
let errorOccured = false;


/**
 * Component
 */
export const PaymentBannerv2: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()
    const router = useRouter()

    /**
     * Application state
     */
    const [premiumPaymentInfo] = useSelector(state => [
        state.premiumPaymentInfo,
    ])
    const [isMobile, setMobile] = React.useState(false)
    const handleWindowSizeChange = () => {
        const Mobile = window.innerWidth < 700 ? true : false
        setMobile(Mobile)
    }
    const [modal, setModal] = React.useState(false)
    const [isPaymentDisabled, setPaymentDisabled] = React.useState(false)
    const btnStyle = isPaymentDisabled ? "mr-xs mb-xs md:mb-none border-none hidden":"mr-xs mb-xs md:mb-none border-none";

    // call your useEffect
    React.useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [])

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (!premiumPaymentInfo.isInitialized) {
            dispatch(actions.fetchPremiumPaymentInfo())
        }
        if(premiumPaymentExtStatusInfo==="partial"){
            setPaymentDisabled(true)
        }
    }, [premiumPaymentInfo.isInitialized])

    /**
     * Feature flags
     */
    const { [Features.CLAIMS_COSTS__PAYMENTS__ALERT__SSA]: alertSsa } =
        useFeatures()

    /**
     * Define template variables
     */
    const premiumPayment =
        premiumPaymentInfo.premiumPaymentInfoItem.premiumPayment
        const premiumPaymentExtStatusInfo = premiumPaymentInfo.premiumPaymentInfoItem.extensions?.metadata?.status;
        const dueDate = formatDate(premiumPayment.dueDate, "MM/dd/yyyy")
    const dueDateMonth = formatDate(premiumPayment.dueDate, "MMMM")
    const totalAmountDue = formatCurrency(premiumPayment.totalAmountDue)
    const pastDueAmount = formatCurrency(premiumPayment.pastDueAmount)
    const paymentDate = formatDate(premiumPayment.paymentDate, "MM/dd/yyyy")
    const isTerminated = premiumPayment.coverageTerminated
    const isPastDue = premiumPayment.isPaymentPastDue
    const isPaidBySocialSecurity = alertSsa
    const isPaymentPending = premiumPayment.pendingPaymentFlag
    const isAutopay = premiumPayment.eftFlag
    const isInvoicePending = premiumPayment.invoicePendingFlag
    const variant =
        (isTerminated || isPastDue) && !isPaidBySocialSecurity
            ? "warning"
            : "info"
    const icon: IconTypes = variant === "warning" ? "warning" : "money-circle"
    const iconColor = variant === "warning" ? "text-warning-300" : "text-info-300"
    const pastDueStr = isPastDue ? " was" : ""
    const amountMessage = `${totalAmountDue} ${pastDueStr} due on ${dueDate}`
    const pastDueAmountMessage = `${pastDueAmount} was due`
    const isPremiumPaymentError: boolean = premiumPaymentInfo.errorMessage !== undefined && premiumPaymentInfo.errorMessage?.length !== 0;
   
    if(isPremiumPaymentError && !errorOccured) {
        dispatch(actions.resetPremiumPaymentInfo());
        errorOccured = true;
    }

    const { t } = useTranslation()
    const titles = t("dashboard.premiumPaymentBanner.titles")
    const messages = t("dashboard.premiumPaymentBanner.messages")

    const title = isTerminated
        ? titles.coverageTerminated
        : isPaidBySocialSecurity
        ? titles.socialSecurity
        : isPastDue
        ? titles.paymentPastDue
        : isPaymentPending
        ? titles.paymentPending
        : isAutopay
        ? titles.premiumPayment
        : titles.paymentDue

    const paymentMessage = isTerminated
        ? messages.coverageWasTerminated
        : isPaidBySocialSecurity
        ? messages.socialSecurity
        : isPastDue
        ? messages.willBeTermed
        : isPaymentPending && isAutopay
        ? messages.autopay + totalAmountDue + " initiated"
        : isPaymentPending
        ? messages.paymentPending + paymentDate
        : isInvoicePending
        ? messages.invoicePending
        : isAutopay
        ? messages.autopay + totalAmountDue + " scheduled for " + dueDateMonth
        : amountMessage

    const description = isPaidBySocialSecurity
        ? messages.checkBalance
        : isTerminated
        ? messages.customerService
        : messages.paymentDisclaimer

    /**
     * Template
     */
    return (
        <React.Fragment>
            <div className="mt-sm mb-xxs">
                {premiumPaymentInfo.isLoading && (
                    <Alert
                        name="dashboard-premium-payment-shimmer"
                        variant={variant}
                        className="gap-y-xs"
                        childrenClassName="flex flex-col xl:flex-row xl:justify-between w-full"
                    >
                        <div>
                            <h6>
                                <Shimmer
                                    placeholder="Loading payment banner..."
                                    value="Loading payment banner..."
                                    isLoading={premiumPaymentInfo.isLoading}
                                />
                            </h6>
                            <h5>
                                <Shimmer
                                    placeholder="Loading payment banner..."
                                    value="Loading payment banner..."
                                    isLoading={premiumPaymentInfo.isLoading}
                                />
                            </h5>
                            <p>
                                <Shimmer
                                    placeholder="Loading payment banner..."
                                    value="Loading payment banner..."
                                    isLoading={premiumPaymentInfo.isLoading}
                                />
                            </p>
                        </div>
                    </Alert>
                )}
                {!premiumPaymentInfo.isLoading && !isPremiumPaymentError && (
                    <Alert
                        name="dashboard-premium-payment-banner"
                        variant={variant}
                        className="w-full"
                        childrenClassName="flex flex-col xl:flex-row xl:justify-between w-full py-xxs"
                        alignItems={isMobile ? "center" : "start"}
                        // alignItems={"end"}
                        hideIcon
                    >
                        <div className="flex flex-col w-full lg:flex-row justify-between">
                            <div className="w-xl">
                                <Icon type={icon} size={24} className={iconColor}/>
                            </div>
                            <div className="grow flex flex-col lg:relative">
                                <div className="grow flex flex-col">
                                    <div className="mt-xs md:mt-xxxs">
                                        <h6 className="mb-none">{title}</h6>
                                    </div>
                                    <div className="font-light mt-none mb-none">
                                        <h5 className="my-xxs">{paymentMessage}</h5>
                                    </div>
                                    {isPastDue &&
                                        !isPaidBySocialSecurity &&
                                        !isTerminated &&
                                        !isAutopay && (
                                            <p className="font-bold">
                                                {pastDueAmountMessage}
                                            </p>
                                        )}
                                    <p className="text-[14px]">
                                        {description}
                                    </p>
                                </div>
                            </div>
                       
                            <div className="flex flex-col md:flex md:flex-row xl:flex-none">
                                {(isTerminated || isPaidBySocialSecurity) && (
                                    <Button
                                        name={"customer-service-link"}
                                        data-test={"customer-service-link"}
                                        label={"Customer Service"}
                                        className="mr-xs mb-xs md:mb-none"
                                        variant="primary"
                                        inverted={true}
                                        onClick={() => {router.push(Routes.support())}}
                                    />
                                )}
                                {!isTerminated && !isPaidBySocialSecurity && (
                                    <Button
                                        name={
                                            isAutopay
                                                ? "manage-autopay-link"
                                                : "set-up-autopay-link"
                                        }
                                        label={
                                            isAutopay
                                                ? "Manage autopay"
                                                : "Set up autopay"
                                        }
                                        onClick={() => setModal(true)}
                                        className={btnStyle}
                                        variant="secondary"
                                    />
                                )}
                                {!isPaidBySocialSecurity && (
                                    <Button
                                        name="go-to-payment-link"
                                        label="Go to Payments"
                                        size="medium"
                                        variant="primary"
                                        className="border-none"
                                        onClick={() => {router.push(Routes.payments())}}
                                    />
                                )}
                            </div>
                        </div>
                        <ExternalLinkModal
                            name={"autopay-leaving-modal"}
                            isVisible={modal}
                            onDismiss={() => setModal(false)}
                            href={Routes.instamedAutoPaymentSSO()}
                            target="_blank"
                        />
                    </Alert>
                )}
                {isPremiumPaymentError && (
                    <>
                        <ErrorDisplay
                            type="errorWithCTA"
                            location = "payment options"
                        />
                    </>
                )}
            </div>
        </React.Fragment>
    )
}
