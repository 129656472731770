import React from "react"
import { actions, useSelector, useDispatch } from "store"
import { selectLatestThreeMedications } from "store/rx/medications/selectors"
import { tagEvent } from "utils/analytics"
import { Avatar, AvatarStack, Button, Card, Icon, Shimmer, formatCase } from "heartwood-component-library"
import router from "next/router"
import Routes from "@/router"
import { startTerm } from "@/components/rx/rx_utils"
import { formatDate } from "@/utils/date"
import { Clickable } from "@/elements"
import ShimmerCard from "./shimmer_card"
import EmptyState from "./empty_state"
import { useTranslation } from "react-i18next"
import { patientFirstName, showPatientInitials } from "@/utils/patient_initials"

/**
 * Component`
 * -----------------------------------------------------------------------------
 */
const DashboardMedications: React.FC = () => {
   
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Application state
     */
    const [medications] = useSelector(state => [
        state.medications,
    ])
    // If there are any family members, we will show initials even if no one has turned on family sharing
    const showInitials = showPatientInitials(medications?.familyMembers)
    const latestMedications = useSelector(selectLatestThreeMedications)
    const hasItems = !medications.isLoading && !!latestMedications.length

    /**
     * Methods
    */
    const firstName = (membershipId: string) => {
        return patientFirstName(medications?.familyMembers, membershipId)
    }

    /**
     * Lifecycle
    */
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (!medications.isInitialized) {
            dispatch(actions.fetchMedications())
        }

    }, [])

    /**
     * Methods
     */
    const onClick = () => {
        // eslint-disable-next-line functional/immutable-data
        router.push(Routes.medications())
    }

    /**
     * Define template variables
     */
    const strings = t("dashboard.recentMedications", { returnObjects: true })

    /**
     * Template
    */
    return (
        !!latestMedications.length && <Card name="dashboard-medication-card" radius="lg" data-test="medications"  className="my-lg !px-[32px]">
            <div className="flex items-center justify-between mb-xs">
                <h5 className="flex-1 mb-none">{strings.title}</h5>
                {!!medications.items.length && (<strong>
                    <Button
                        name="see-medications-button"
                        label={strings.viewMoreButton.label}
                        variant="link"
                        href={Routes.medications()}
                        onClick={() => onClick()}
                        className="px-xs pt-none"
                        data-analytics-id="see-medications-button"
                        data-analytics-event="dfwEvent"
                    />
                </strong>
                )}
            </div>
            {medications.isLoading && (
                <React.Fragment>
                    <ShimmerCard/>
                    <ShimmerCard/>
                </React.Fragment>
            )}
            {!medications.isLoading &&
                latestMedications.map(function (medication, index) {
                    return <div key={index} className="flex items-center py-xs border-b border-light-100 dark:border-dark-100 last:border-none">
                        <DashboardMedicationItem
                            key={index}
                            medicationId={medication.id}
                            shimmer={medications.isLoading}
                            showInitials={showInitials}
                            firstName={firstName(medication?.membershipId)}
                        />
                    </div>
                })}

            {/* Empty state */}
            {!hasItems && !medications.isLoading && <EmptyState label={strings.emptyState.label} />}
        </Card>
    )
}

interface DashboardMedicationItemProps {
    readonly medicationId?: string
    readonly shimmer?: boolean
    readonly showInitials?: boolean
    readonly firstName?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const DashboardMedicationItem: React.FC<DashboardMedicationItemProps> = props => {
    /**
     * Application state
     */
    const [medications] = useSelector(state => [
        state.medications,
    ])
    const item = medications.items.find(item => item.id === props.medicationId)

    /**
     *  tagging click
     */
    const onClick = () => {
        const tagId = item.rxTreatment?.id || item.id
        // eslint-disable-next-line functional/immutable-data
        router.push({
            pathname: Routes.browseMedication(item.medicationTreatmentId),
            query: { start: startTerm() },
        })

        tagEvent({
            tealium_event: "link",
            data_analytics_id: "dashboard-medication-item",
            link_text: item.rxTreatment?.primaryName,
            link_url: "",
            rx_medication_id: tagId,
        })
    }

    const title = item.rxTreatment?.primaryName || item?.displayName
    const subtitle =item.rxTreatment?.secondaryName
    const lastFillDate = formatDate(item.lastFillDate, "MM/dd/yyyy")
            
    /**
     * Template
     */
    return (
        <Clickable
            datatype="dashboard-medication-card"
            className="w-full flex items-center space-x-xs"
            onClick={onClick}
            data-test={"dashboard-medication-item-" + item.id}
        >
            {props.showInitials && props.firstName?.length > 0 ? (
                <AvatarStack
                    items={[
                        { icon: "pills", size: "small" },
                        {
                            label: props.firstName,
                            variant: "primary",
                            size: "small",
                        },
                    ]}
                />
            ) : (
                <Avatar icon="pills" size="small" />
            )}
            <div className="flex-1">
                <div className="font-bold mb-xxs">
                    <Shimmer
                        placeholder={title}
                        value={title}
                        isLoading={props.shimmer}
                    />
                </div>
                {subtitle?.length > 0 && (
                    <React.Fragment>
                        <div className="text-sm mb-xxxs block min-[389px]:hidden">
                            <Shimmer
                                placeholder={subtitle}
                                value={subtitle}
                                isLoading={props.shimmer}
                            />
                        </div>
                        <div className="text-md mb-xxxs hidden min-[389px]:block">
                            <Shimmer
                                placeholder={subtitle}
                                value={subtitle}
                                isLoading={props.shimmer}
                            />
                        </div>
                    </React.Fragment>
                )}
                {lastFillDate && (
                    <div className="block md:hidden">
                        <React.Fragment>
                            <div className="text-sm mb-xxxs block min-[389px]:hidden">last filled {lastFillDate}</div>
                            <div className="text-md mb-xxxs hidden min-[389px]:block">last filled {lastFillDate}</div>
                        </React.Fragment>
                    </div>
                )}

            </div>
            <div className="flex flex-col justify-center md:pl-xs">
                <div className="flex items-center mb-xxs space-x-xs">
                    {lastFillDate && (
                        <small className="hidden md:block">last filled {lastFillDate}</small>
                    )}
                    <Icon
                        type="chevron-right-sm"
                        className="text-link"
                    />
                </div>
            </div>
        </Clickable>   
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default DashboardMedications