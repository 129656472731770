import { Avatar, Shimmer } from "heartwood-component-library"

/**
 * Subcomponent: ShimmerClaim
 * -----------------------------------------------------------------------------
 */
const ShimmerCard: React.FC = () => {
    return (
        <div
            className="mt-xs mb-sm flex justify-between border-none"
        >
            <div className="flex flex-col md:flex-row">
                <Avatar shimmer={true} className={"mb-sm md:mb-0"} />
                <div className="flex-1 md:px-sm">
                    <div className="mb-xxs -mt-two">
                        <Shimmer placeholder="A long line of placeholder text..." />
                    </div>
                    <div className="mb-xxs">
                        <Shimmer placeholder="Short line of placeholder text..." />
                    </div>
                    {/* <div className="mb-0 -mt-two">
                        <Shimmer placeholder="Provider number" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ShimmerCard