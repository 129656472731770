import React from "react"
import { useFeatures } from "store"
import Features from "utils/features"
import { Routes } from "router"
import { Card, Icon } from "heartwood-component-library"
import { Clickable } from "@/elements"
import { getEnvironment } from "@/utils/env"
import { useTranslation } from "react-i18next"

const BeyondWell: React.FC = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Feature flags
    */
    const {
        [Features.CIS]: isCIS,
    } = useFeatures()

    // DEV/UAT Testing
    const env = getEnvironment()
    const showAll = ["dev", "uat"].includes(env) &&  document.location.toString().split("?").includes("showAll")

    /**
     * Define template variables
     */
    const strings = t("dashboard.wellness.beyondWell.card", { returnObjects: true })

    // Render nothing if all CIS rules evaluate to false
    if (!isCIS && !showAll) return <React.Fragment />

    return (
        <Card name="beyondwell-card" radius="lg" data-test="medications">
            <Card.Content className="xl:flex justify-between items-center">
                <div className="md:flex flex-1 items-center">
                    <img
                        src="/assets/img/beyondwell-logo.svg"
                        className={"h-xs mr-sm mt-xxs"} 
                        alt="BeyondWell logo" />
                    <div className="ml-xxxs md:mt-none mt-xs md:ml-none items-center">
                        <strong>{strings.title}</strong>
                        <div className="my-xxxs text-muted">{strings.subtitle}</div>
                    </div>
                </div>
                <Clickable
                    name="beyondwell-button"
                    target="_blank"
                    isExternal={true}
                    href={Routes.beyondWellSSO()}>
                    <div className="flex items-center ml-xxxs md:ml-none xl:px-xs text-primary-vivid dark:text-accent">
                        <strong className="block mb-xxxs pr-xxs">{strings.goTo}</strong>
                        <Icon type="arrow-top-right" size={24} className="mb-xxs"/>
                    </div>
                </Clickable>
            </Card.Content>
            <Card.Content className="pt-xs border-t border-muted">
                <strong>{strings.bodyHeading}</strong>
                <p className="mt-xxs text-muted mb-xs">{strings.body}</p>
                <Clickable
                    name="beyondwell-link"
                    target="_blank"
                    isExternal={true}
                    href={Routes.beyondWellSSO()}
                    className="pl-none"
                    analytics={{
                        data_analytics_id: "beyondwell-link",
                        link_url: Routes.beyondWellSSO(),
                    }}
                >
                    <div className="underline text-primary dark:text-accent">
                        {strings.signIn}
                    </div>
                </Clickable>
            </Card.Content>
        </Card>
    )
}

export default BeyondWell