/**
 * Provider Search Card
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Card, Clickable } from "elements"
import { Pictogram } from "heartwood-component-library"
import { useTranslation } from "react-i18next"
import { Routes } from "router"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ProviderSearchCard = () => {
    const { t: strings } = useTranslation()
    const item = {
        name: "provider-search-joint-admin",
        title: strings("jointAdmin.providerSearchTitle"),
        subtitle: strings("jointAdmin.providerSearchBody"),
        href: Routes.findDoctor(),
        target: "_blank",
    }
    const analytics = { link_text: item.title }

    /**
      * Template
      */
    return (
        <div className="min-w-[240px] w-full">
            <Clickable
                href={item.href}
                target={item.target}
                className="bg-secondary-50"
                isExternal={true}
                analytics={analytics}
            >
                <Card
                    name={item.name}
                    className="h-full bg-opacity-0"
                >
                    <div className="bg-secondary-50 py-lg text-center w-full h-full">
                        <div className="relative h-[80px] w-[80px] mx-auto flex items-center justify-center rounded-circle bg-white">
                            <Pictogram
                                alt=""
                                regence="reg_magnify_pear"
                                size="small"
                            />
                        </div>
                        <div className="mt-xs mb-none text-xl">{item.title}</div>
                        <div className="mt-xs text-link underline">
                            {item.subtitle}
                        </div>
                    </div>
                </Card>
            </Clickable>
        </div>
    )

}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ProviderSearchCard
