/**
 * Joint Admin Dashboard
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Layout from "components/layout"
import ProviderSearchCard from "./provider_search"
import EstimateCostsCard from "./estimate_costs"
import SubmitAClaimCard from "./submit_a_claim"
import GeneralInfoCard from "./general_information"
import WebsiteSupportCard from "./website_support"
import { useFeatures, useSelector } from "store"
import Features from "@/utils/features"
import GroupSpecificBanner from "./group_specific_banner"
import EmailValidationPopup from "@/components/account/email_verification_popup"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const JointAdminDashboard = () => {
    /**
     * Feature Toggle
     */
    const {
        [Features.AMERIBEN]: isAmeriBen,
        [Features.DFW_MY_ACCOUNT_ENABLED]: isDFWMyAccountEnabled,
    } = useFeatures()

    const myAccount = useSelector(state => state.myAccount)
    const [needsEmailVerification, setNeedsEmailVerification] = React.useState(
        myAccount.profile.emailVerificationStatus === "PENDING" &&
        isDFWMyAccountEnabled,
    );

    const maxCols = isAmeriBen ? 1 : 2;

    return (
        <Layout title="Home" noPadding={false} hideNavigation={true}>
            {/* copied from regular dashboard to add email validation modal */}
            <EmailValidationPopup
                showPopup={needsEmailVerification}
                onDismiss={() => setNeedsEmailVerification(false)}
            />

            <div className={`grid grid-cols-1 md:grid-cols-${maxCols} gap-sm md:gap-lg mb-sm md:my-md`}>
                <ProviderSearchCard />
                <EstimateCostsCard />
            </div>
            <GroupSpecificBanner />
            <div className="flex items-center justify-left mb-xs">
                <h5 className="mb-none">Resources</h5>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-xs">
                <SubmitAClaimCard />
                <GeneralInfoCard />
                <WebsiteSupportCard />
            </div>
        </Layout>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default JointAdminDashboard
