/**
 * CampaignCard
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Routes from "router"
import { actions, useDispatch } from "store"
import { Avatar, AvatarStack, Badge, Icon, IconTypes } from "heartwood-component-library"
import { Clickable } from "elements"
import router from "next/router"
import { tagEvent } from "@/utils/analytics"
import { InsightProps } from "@/components/props/insight_props"
import { campaignNonRx } from "@/utils/rx/utilities"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface componentProps {
    readonly id: string
    readonly guid: string
    readonly category: string
    readonly title: string
    readonly subtitle: string
    readonly type: string
    readonly href: string
    readonly label: string
    readonly showInitials?: boolean
    readonly initials?: string
    readonly onClick?: VoidFunction
    readonly membershipId?: string
    readonly isNew: boolean
    readonly nonRx: boolean
    readonly opportunity_Id: string
}

/**
 * Component: CampaignCard
 * -----------------------------------------------------------------------------
 * This is the item that shows on the Dashboard
 */
export const CampaignCard: React.FC<InsightProps> = props => {
    const campaignId = props.action?.campaignId
    const campaignRoute = props.action?.route

    //TODO: Address bad data issues
    const campaignRouteUrl = campaignRoute ? new URL(campaignRoute) : null
    const oppId = campaignRouteUrl?.searchParams.get("opportunity_id")
    const opportunity_Id = oppId ? `?opportunity_id=${oppId}` : null

    // use regex here
    const isCampaignRoute =
        campaignRoute?.split("/").slice(3, 4)[0] == "campaign"
    const campaignGuid = isCampaignRoute
        ? campaignRoute?.split("/").slice(4, 5)[0].split("?").slice(0, 1)[0]
        : null
    const campaignLink = campaignGuid
        ? Routes.browseCampaign(campaignGuid) + opportunity_Id
        : campaignId
            ? Routes.browseCampaign(campaignId) + opportunity_Id
            : campaignRoute    
    const nonRx = campaignNonRx(props.analyticsTitle, props.title)

    const params = {
        id: campaignId,
        guid: campaignGuid,
        category: props.category,
        title: props.title,
        subtitle: props.subtitle,
        type: props.type,
        href: campaignLink + "&start=home",
        label: props.action.label,
        initials: props.initials,
        showInitials: props.showInitials,
        onClick: props.onClick,
        membershipId: props.membershipId,
        isNew: props.isNew,
        nonRx: nonRx,
        opportunity_Id: opportunity_Id,
    }
    return <CampaignComponentCard {...params} />
}

/**
 * Sub Component: CampaignCard
 * -----------------------------------------------------------------------------
 */
const CampaignComponentCard: React.FC<componentProps> = props => {
    const dispatch = useDispatch()

    /**
     * Template variables
     */
    const campaignIcon = props.nonRx
        ? "check-circle-alt"
        : "pills" as IconTypes

    /**
     *  tagging click
    */
    const onClick = () => {
        dispatch(
            actions.updateCampaigns({
                isItemInitialized: false,
                isInitialized: false,
            }),
        )
        router.push(props.href)
        tagEvent({
            tealium_event: "link",
            data_analytics_id: "dashboard-campaign-item",
            link_text: "link",
            link_url: props.href,
        })
        props.onClick()
    }

    /**
     * Template
     */
    return (
        <Clickable
            datatype={`${props.type}-campaign-insights-card`}
            className="w-full flex items-center space-x-xs"
            onClick={onClick}
            name={"campaign-insights-card-" + props.guid + props.opportunity_Id}
        >
            {props.isNew && (
                <Badge />
            )}
            {props.showInitials && props.initials?.length > 0 ? (
                <AvatarStack
                    items={[
                        { icon: campaignIcon, size: "small" },
                        {
                            label: props.initials,
                            variant: "primary",
                            size: "small",
                        },
                    ]}
                />
            ) : (
                <Avatar icon={campaignIcon} size="small" />
            )}

            <div className="flex-1">
                {props.title}
            </div>
            <div className="flex flex-col justify-center" >
                <Icon
                    type="chevron-right-sm"
                    className="text-link"
                />
            </div>
        </Clickable>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default CampaignCard
