/**
 * Alerts – Styx test alert
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes, Markdown } from "elements"
import { getBodyWithTokens } from "@/utils/markdown"
import { StyxFragment, fetchManagedContentFragment } from "@/utils/styx"

export interface Token {
    readonly value: string
    readonly label: string
    readonly type: string
}
export interface Notice {
    readonly level?:
    | "info"
    | "warning"
    | "default"
    | "error"
    | "success"
    | "notAllowed"
    | "banner"
    readonly title?: string
    readonly bodyWithTokens?: string
    readonly tokens?: ReadonlyArray<Token>
    readonly icon?: string
    readonly onDismiss?: VoidFunction
}


/**
 * Component
 * -----------------------------------------------------------------------------
 */
const NoticeComponent: React.FC<StyxFragment<Notice>> = props => {
    /**
     * Application states
     */
    const [fragmentStatus, setFragmentStatus] = React.useState("pristine")
    const [fragment, setFragment] = React.useState<StyxFragment<Notice>>(props)
    const content = fragment?.content?.[0]

    /**
     * Fetch support managed content fragment
     */
    React.useEffect(() => {
        if (fragmentStatus === "pristine"){
            fetchManagedContentFragment(props.fragmentId, setFragmentStatus, setFragment)
        }
    }, [])

    /*
     * Template
     */
    return (
        fragmentStatus === "completed" && content && (
            <div className={`content`}>
                <div className={`flex shadow-sm bg-warning-50 dark:bg-warning-300 border-warning py-xs px-sm rounded-sm border-b-xxl`}>
                    {content?.icon && (
                        <div className={`mr-xs items-top text-warning-vivid dark:text-warning-100`}>
                            <Icon
                                type={content?.icon as IconTypes}
                                className="transform -translate-y-two"
                            />
                        </div>
                    )}
                    <div className={`mr-xs items-bottom`}>
                        {content.title && <h6 className={"font-medium"}>{content.title}</h6>}
                        <Markdown content={getBodyWithTokens(content.bodyWithTokens, content.tokens)} config={{ ADD_ATTR: ["target"] }} />
                    </div>
                </div>
            </div>
        )
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default NoticeComponent