export const MedicationTherapyManagement = {
    blu:
        "https://www.regence.com/go/all/2024/personal-med-list.pdf",
    asur:
        "https://www.asuris.com/go/ew/2024/personal-med-list.pdf",
}

export const StepTherapyProgramDrugList = {
    blu:
        "https://www.regence.com/go/all/2024/step-therapy.pdf",
    asur:
        "https://www.asuris.com/go/all/2024/step-therapy.pdf",
}

export const MemberPlanCoverage = {
    ew: {
        pdp: {
            basic: "https://www.asuris.com/go/ew/pdp/2024/coverage-det-basic",
            enhanced: "https://www.asuris.com/go/ew/pdp/2024/coverage-det-enhanced"
        },
        ppo: {
            primary: "https://www.asuris.com/go/ew/ppo/2024/coverage-det-primary",
            classic: "https://www.asuris.com/go/ew/ppo/2024/coverage-det-classic"
        }
    },
    id: {
        pdp: {
            basic: "https://www.regence.com/go/id/pdp/2024/coverage-det-basic",
            enhanced: "https://www.regence.com/go/id/pdp/2024/coverage-det-enhanced",
        },
        hmo: {
            default: "https://www.regence.com/go/id/hmo/2024/coverage-det-hmo",
            align: "https://www.regence.com/go/id/hmo/2024/coverage-det-align",
        },
        ppo: {
            primary: "https://www.regence.com/go/id/ppo/2024/coverage-det-primary",
            classic: "https://www.regence.com/go/id/ppo/2024/coverage-det-classic",
            enhanced: "https://www.regence.com/go/id/ppo/2024/coverage-det-enhanced"
        }
    },
    or: {
        hmo: {
            default: "https://www.regence.com/go/or/hmo/2024/coverage-det-hmo",
            plus: "https://www.regence.com/go/or/hmo/2024/coverage-det-hmo-plus"
        },
        ppo: {
            primary: "https://www.regence.com/go/or/ppo/2024/coverage-det-primary",
            classic: "https://www.regence.com/go/or/ppo/2024/coverage-det-classic",
            enhanced: "https://www.regence.com/go/or/ppo/2024/coverage-det-enhanced"
        }
    },
    ut: {
        pdp: {
            basic: "https://www.regence.com/go/ut/pdp/2024/coverage-det-basic",
            enhanced: "https://www.regence.com/go/ut/pdp/2024/coverage-det-enhanced"
        },
        ppo: {
            primary: "https://www.regence.com/go/ut/ppo/2024/coverage-det-primary",
            classic: "https://www.regence.com/go/ut/ppo/2024/coverage-det-classic",
            enhanced: "https://www.regence.com/go/ut/ppo/2024/coverage-det-enhanced"
        }
    },
    ww: {
        hmo: {
            default: "https://www.regence.com/go/ww/hmo/2024/coverage-det-hmo",
            plus: "https://www.regence.com/go/ww/hmo/2024/coverage-det-hmo-plus"
        },
        ppo: {
            core: "https://www.regence.com/go/ww/ppo/2024/coverage-det-core",
            primary: "https://www.regence.com/go/ww/ppo/2024/coverage-det-primary",
            classic: "https://www.regence.com/go/ww/ppo/2024/coverage-det-classic",
            enhanced: "https://www.regence.com/go/ww/ppo/2024/coverage-det-enhanced"
        }
    }
}

export const WalgreensSpecialtyPharmacy = "https://walgreensmailservice.com/"
export const PostalPrescriptionServices = "https://www.ppsrx.com/health/pps?redirectUrl=/rx/dashboard"
export const ExpressScript = "https://www.express-scripts.com/"
export const AmazonPharmacy = "https://pharmacy.amazon.com/"
export const PillpackPharmacy = "https://my.pillpack.com/signup"

export const TransitionPolicy = {
    blu: "https://www.regence.com/go/all/2024/transition-policy-part-d.pdf",
    asur: "https://www.asuris.com/go/all/2024/transition-policy-part-d.pdf"
}

export const MemberReimbursementClaimForm = {
    regence:
        "https://beonbrand.getbynder.com/m/d9f582b32894de0/original/Member-Reimbursement-Claim-Form-OR.pdf",
    regence_id:
        "https://beonbrand.getbynder.com/m/2c76d462415c784f/original/Member-Reimbursement-Claim-Form-ID.pdf",
    regence_ut:
        "https://beonbrand.getbynder.com/m/1041dcdc227e4dd1/original/Member-Reimbursement-Claim-Form-UT.pdf",
    regence_ww:
        "https://beonbrand.getbynder.com/m/a1671007cbc9c57/original/Member-Reimbursement-Claim-Form-WA.pdf",
    asuris:
        "https://beonbrand.getbynder.com/m/185f6ac6c5be7765/original/Member-Reimbursement-Claim-Form-AS.pdf",
    bridgespan:
        "https://beonbrand.getbynder.com/m/534bd1ef013a0dfa/original/BSH-Reimbursement-form-ID-OR-UT.pdf",
    bridgespan_ww:
        "https://beonbrand.getbynder.com/m/2cc5d5a7030abdc5/original/BSH-Reimbursement-form-WA.pdf",
}
