/**
 * Program Card
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Pictogram, PictogramProps, Icon, formatCase, Pill } from "heartwood-component-library"
import { Program } from "store/programs/reducer"
import { onlifeActions } from "@/store/onlife/reducer"
import { useDispatch } from "@/store"
import { Clickable } from "@/elements"

/**
 * Map Product ID to Pictogram Name
 * -----------------------------------------------------------------------------
 */

const mdlivePictogram: PictogramProps = {
    alt: "",
    regence: "reg_medical_team_emerald",
    asuris: "ahs_doctor",
    bridgespan: "bsh_md_live",
}

const pictograms: Record<string, Pick<PictogramProps, "regence" | "asuris" | "bridgespan">> = {
    ASANTE_EMPLOYER_CTA: { regence: "reg_star_mint", asuris: "ahs_check_green" },
    WINCO_EMPLOYER_CTA: { regence: "reg_star_mint", asuris: "ahs_check_green" },
    RESEARCH_RX: { regence: "reg_microscope_mint", asuris: "ahs_alternative_medicine", bridgespan: "bsh_rx_medkit_combo" },
    SILVER_AND_FIT: { regence: "reg_dumbbell_pear", asuris: "ahs_health_and_fitness" },
    ABLE_TO: { regence: "reg_lotus_mint", asuris: "ahs_member_service" },
    ADVICE_24: { regence: "reg_nurseline_24_7_gold", asuris: "ahs_nurse_chat", bridgespan: "bsh_advice_24" },
    TELEHEALTH: { regence: "reg_telehealth_coral", asuris: "ahs_telehealth_1", bridgespan: "bsh_telehealth" },
    ADVANTAGES_DISCOUNTS: { regence: "reg_piggy_bank_turquoise", asuris: "ahs_piggy_bank", bridgespan: "bsh_piggy_bank" },
    MEDICARE_DISCOUNTS_RESOURCES: { regence: "reg_piggy_bank_turquoise", asuris: "ahs_piggy_bank", bridgespan: "bsh_piggy_bank" },
    ASK_DOCTOR: { regence: "reg_doctor_gray", asuris: "ahs_doctor" },
    BABYWISE: { regence: "reg_baby_gray", asuris: "ahs_baby" },
    BUMP2BABY: { regence: "reg_baby_gray", asuris: "ahs_baby", bridgespan: "bsh_child" },
    CONDITION_MANAGER: { regence: "reg_heart_hands_gray", asuris: "ahs_weight_scale" },
    CARE_MANAGEMENT: { regence: "reg_heart_hands_gray", asuris: "ahs_weight_scale", bridgespan: "bsh_handshake" },
    ESTIMATE_COSTS: { regence: "reg_calculator_emerald", asuris: "ahs_treatment-cost", bridgespan: "bsh_coverage" },
    DOD: { regence: "reg_medical_team_coral", asuris: "ahs_doctor_team", bridgespan: "bsh_md_live" },
    DOD_VPC: { regence: "reg_medical_team_coral", asuris: "ahs_doctor_team", bridgespan: "bsh_md_live" },
    MED_SUPPLIES: { regence: "reg_crutches_gray", asuris: "ahs_shop_1" },
    MDLIVE_VENDOR: mdlivePictogram,
    MDLIVE_TELEH: mdlivePictogram,
    MDLIVE_TELEV: mdlivePictogram,
    MDLIVE_TELEX: mdlivePictogram,
    MYSTRENGTH: { regence: "reg_call_24_7_turquoise", asuris: "ahs_24-7" },
    PALLIATIVE_CARE: { regence: "reg_care_gold", asuris: "ahs_care", bridgespan: "bsh_care" },
    PREVENTATIVE_CARE: { regence: "reg_apple_emerald", asuris: "ahs_prevention", bridgespan: "bsh_preventive_health" },
    EMPOWER: { regence: "reg_empower_coral", asuris: "ahs_compass" },
    WA_SUBSTANCE_ABUSE: { regence: "reg_care_turquoise", asuris: "ahs_care", bridgespan: "bsh_care" },
    BSH_SUBSTANCE_ABUSE: { bridgespan: "bsh_care" },
    EXPERT_OPINION: { regence: "reg_chat_gold", asuris: "ahs_doctor_team" },
    CHANGE_PCP: { regence: "reg_stethoscope_coral", asuris: "ahs_stethoscope" },
    HINGE_HEALTH: { regence: "reg_dumbbell_gray", asuris: "ahs_weight_scale" },
    DIABETES_PREVENTION: { regence: "reg_scale_mint", asuris: "ahs_weight_scale" },
    BEHAVIORAL_HEALTH: { regence: "reg_brain_mint", asuris: "ahs_mental_health", bridgespan: "bsh_contact_us" },
    BEHAVIORAL_HEALTH_MEDIGAP: { regence: "reg_brain_mint", asuris: "ahs_mental_health", bridgespan: "bsh_contact_us" },
    GENDER_AFFIRMING_CARE: { regence: "reg_heart_geometric_gray", asuris: "ahs_care", bridgespan: "bsh_care" },
    UMP_BENEFITS_PEBB: { regence: "reg_bar_graph_emerald" },
    UMP_BENEFITS_SEBB: { regence: "reg_bar_graph_emerald" },
    UMP_HEALTH_PEBB: { regence: "reg_apple_emerald" },
    UMP_HEALTH_SEBB: { regence: "reg_apple_emerald" },
    EMPLOYEE_ASSISTANCE_PROGRAM: { regence: "reg_brain_mint", asuris: "ahs_mental_health", bridgespan: "bsh_contact_us" },
    INDIVIDUAL_ASSISTANCE_PROGRAM: { regence: "reg_brain_gold", asuris: "ahs_mental_health", bridgespan: "bsh_contact_us" },
    HEAL: { regence: "reg_house_coral", asuris: "ahs_home", bridgespan: "bsh_home" },
    DISPATCH_HEALTH: { regence: "reg_house_gold", asuris: "ahs_home", bridgespan: "bsh_home" },
    HCA_SMARTHEALTH: { regence: "reg_star_mint" },
    UW_24_NURSE_PEBB: { regence: "reg_nurseline_24_7_gold" },
    UW_24_NURSE_SEBB: { regence: "reg_nurseline_24_7_gold" },
    HEALTHY_BENEFITS: { regence: "reg_apple_gold" },
    HEALTHY_BENEFITS_FORM: { regence: "reg_apple_gold" },
    MENTAL_HEALTH_PROVIDER: { regence: "reg_magnify_coral", asuris: "ahs_search-choose", bridgespan: "bsh_contact_us" },
    SAMHSA: { regence: "reg_care_mint", asuris: "ahs_care", bridgespan: "bsh_care" },
    BEYONDWELL: { regence: "reg_star_mint" },
    FORTIVE_LIVONGO_HYPERTENSION: { regence: "reg_blood_pressure_coral" },
    FORTIVE_VIRTA_DIABETES: { regence: "reg_blood_test_coral" },
    FORTIVE_PROGENY_INFERTILITY: { regence: "reg_pregnancy_gray" },
    FORTIVE_SPRING_HEALTH: { regence: "reg_lightbulb_gray" },
    SUICIDE_CRISIS_LIFELINE: { regence: "reg_hearts_coral", asuris: "ahs_care", bridgespan: "bsh_care" },
    NATIONAL_EATING_DISORDER_ASSOCIATION: { regence: "reg_head_with_apple_mint", asuris: "ahs_prevention", bridgespan: "bsh_star" },
    FIND_THE_RIGHT_CARE: { regence: "reg_lightbulb_gold", asuris: "ahs_lightbulb", bridgespan: "bsh_search" },
    OVER_THE_COUNTER: { regence: "reg_piggy_bank_gold", asuris: "ahs_piggy_bank", bridgespan: "bsh_piggy_bank" },
    MEDADVANTAGE_INCENTIVES_OTC: { regence: "reg_piggy_bank_gold", asuris: "ahs_piggy_bank", bridgespan: "bsh_piggy_bank" },
    MEDADVANTAGE_INCENTIVES: { regence: "reg_piggy_bank_gold", asuris: "ahs_piggy_bank", bridgespan: "bsh_piggy_bank" },
    TELEHEALTH_MEDIGAP: { regence: "reg_care_mint", asuris: "ahs_care", bridgespan: "bsh_care" },
    OMADA: { regence: "reg_scale_turquoise", asuris: "ahs_weight_scale" },
    EXPERIAN: { regence: "reg_lock_turquoise" },
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ProgramCard = (item: Program) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Component states
     */
    const preventDefaultPrograms = ["ADVICE_24", "EMPOWER"]
    const externalIcon = item.external ? "arrow-top-right" : "chevron-right-sm"
    const href = item.url

    /**
     * Methods
     */
    const onClickFunctions = {
        EMPOWER: () => dispatch(onlifeActions.ssoButtonClicked()),
    }
    
    /**
     * Analytics
     */
    const linkUrls = { EMPOWER: "/sso/onlife/default" }
    const analytics = item.url
        ? { link_text: item.title }
        : { link_url: linkUrls[item.id], link_text: item.title }

    /**
     * capitalize if more than one word
     */
    const title = item.title?.split(" ")?.length >= 2 ? formatCase(item.title, "capitalize") : item.title

    /**
     * Template
     */
    return (
        <Clickable
            datatype-type={item.id + "-resource-card"}
            name={item.id + "-card"}
            href={item.url}
            target={item.external ? "_blank" : ""}
            isExternal={item.showExternalMessage}
            preventDefault={preventDefaultPrograms.includes(item.id)}
            onClick={onClickFunctions[item.id] || item.onClick}
            isExternalOnClick={item.isExternalOnClick}
            className="w-full flex items-center space-x-xs"
        >
            <Pictogram alt="" {...pictograms[item.id]} className="m-auto w-lg h-lg" />
            <div className="flex-1">
                <div className="font-bold mb-xxs text-link">
                    {title}
                </div>
                {item.pills && (
                    <div className="my-xxs">
                        <Pill
                            label={item.pills[0].label}
                            variant="success"
                            size="small"
                        />
                    </div>
                )}
                {item.description?.length > 0 && (
                    <div className="text-md mb-xxxs">
                        {item.description}
                    </div>
                )}
                {item.subDescription?.length > 0 && (
                    <div className="text-md mb-xxxs">
                        {item.subDescription}
                    </div>
                )}
                {item.contact && (
                    <strong>
                        <p className="text-sm text-primary mt-xxxs md:hidden">
                            {item.contact}
                        </p>
                    </strong>
                )}
            </div>
            <Icon
                type={externalIcon}
                className="text-link"
            />
        </Clickable>

    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ProgramCard
