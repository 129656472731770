import { actions, useDispatch, useFeatures, useSelector } from "@/store"
import { getEnvironment } from "@/utils/env"
import Features from "@/utils/features"
import { Card, Pictogram, Pill, PillProps } from "heartwood-component-library"
import { useTranslation } from "react-i18next"
import { ProviderItem } from "@/store/doctors-and-providers/types"
import { capitalize } from "@/store/account/utils"
import Routes from "@/router"
import React from "react"
import { Program } from "@/store/programs/reducer"
import { defineSortedPrograms, removeOrderedPrograms } from "@/store/programs/utils"
import ShimmerCard from "./shimmer_card"
import CareLink from "./care_link"

const resourceOrderBSH = [
    "DISPATCH_HEALTH",
    "ADVANTAGES_DISCOUNTS",
    "BEHAVIORAL_HEALTH",
    "ESTIMATE_COSTS",
    "GENDER_AFFIRMING_CARE",
    "WA_SUBSTANCE_ABUSE",
    "TELEHEALTH",
]

const FlagIds = [
    "DISPATCH_HEALTH",
]

const removalIds = [
    "ESTIMATE_COSTS",
]

const QuickAccess = (props: {
    readonly setShowAdvice24Dialog: (value: boolean) => void
    readonly mdx?: boolean
}) => {

    /**
     * Hooks
     */
    const { t } = useTranslation()
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const env = getEnvironment()
    const { account, app, programs } = useSelector(state => state)
    const brand = app.brand
    const profile = account.profile
    const isLoading = programs.status === "pending"
    const isBSH = brand === "bridgespan"

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (programs.status === "pristine") {
            dispatch(actions.fetchPrograms())
        }
    }, [])

    const sortResources = (programs: ReadonlyArray<Program>, order: ReadonlyArray<string>): readonly Program[] => {
        return order.reduce((acc: readonly Program[], o: string) => {
            const program = programs.find(p => p.id === o);
            return program ? [...acc, program] : acc;
        }, []);
    }

    const bshPrograms = programs.items.filter(program => program.category.includes("BSH"))

    const updatedResourceOrderBSH = removeOrderedPrograms(
        bshPrograms,
        FlagIds,
        resourceOrderBSH,
        removalIds,
    )

    /**
     * Feature flags
     */
    const {
        [Features.COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___URGENT_CARE_NEAR_YOU]: findCareUrgentCareNearYou,
        [Features.BEHAVIORAL_HEALTH_FIND_CARE]: hcaBehavioralHealthFindCare,
        [Features.DASHBOARD__BEHAVIORAL_HEALTH_CARD]: behavioralHealthFindCare,
        [Features.HCA_GENERAL]: hca,
        [Features.CIS]: cis,
        [Features.MEDIGAP]: isMedigap,
        [Features.RESOURCE_ADVICE_24]: advice24,
        [Features.DISPATCH_HEALTH]: urgentCareAtHome,
        [Features.PEBB_24_HOUR_NURSELINE]: uwPebb24HrNurseLine,
        [Features.SEBB_24_HOUR_NURSELINE]: uwSebb24HrNurseLine,
    } = useFeatures()

    /**
     * Methods
     */
    type deepLinkTypes = "urgentCare" | "emergencyRoom" | "provider"

    const createDeeplink = (brand: string, city: string, state: string, key: deepLinkTypes, providerItem?: ProviderItem) => {
        const brandName = brand === "bridgespan" ? "bridgespanhealth" : brand
        const params = {
            "urgentCare": "Urgent Care",
            "emergencyRoom": "Emergency Room",
            "provider": providerItem?.providerName,
        }
        const hsqEnv = env !== "prd" ? "providerstest" : "providers"
        const hsqDeepLinkUrl = "https://" + hsqEnv + "." + brandName + ".com/auth/realms/" + capitalize(brandName) + "-Medical/protocol/saml/clients/hsq?RelayState="
        const uri = key === "provider"
            ? "/healthsparq/#/one/auth/search/location=" + city + ", " + state + "&query=" + providerItem?.providerName + "&radius=150&searchCategory=Provider_Person_Name"
            : "/healthsparq/#/one/auth/search/isPromotionSearch=true&key=" + params[key] + "&location=" + city + ", " + state + "&page=1&query=" + params[key] + "&radius=25&searchCategory=ORG_SUBTYPE&searchType=SYNONYM_ORGANIZATION"

        return hsqDeepLinkUrl + encodeURIComponent(uri)
    }

    /***
     * Define template variables
     */
    const address = profile.address
    const city = address?.city
    const state = address?.state
    const strings = t("dashboard.quickAccess", { returnObjects: true })

    const items: readonly Program[] = hca
        ? programs.items.filter(program => program.category.includes("Hca"))
        : cis
            ? programs.items.filter(program => program.category.includes("Cis"))
            : isBSH && !isMedigap
                ? sortResources(bshPrograms, updatedResourceOrderBSH)
                : defineSortedPrograms(programs, "home")

    const targetItems = cis ? programs.items : items
    const telehealth = targetItems.find(item => item.id === "TELEHEALTH")
    const isProgramsCompleted = programs.status === "completed"
    const showNurseLine = uwPebb24HrNurseLine || uwSebb24HrNurseLine

    // Quick Access Card Id
    const cardId = props.mdx ? "quick-access-mdx" : "quick-access"

    return (
        <Card id={cardId} name={"quick-access-card"} radius="lg" className="!px-[32px]">
            <Card.Content className="-mx-xs">
                <div className="flex justify-between mt-xxs">
                    <h5 className="flex-1 mr-xxs">{strings.card.title}</h5>
                    <Pill
                        label="In network"
                        iconLeading="check"
                        size="large"
                        variant="success"
                    />
                </div>
                <div className={`flex items-center justify-between`}>
                    <ul className="list-none px-none mb-none">
                        {/* Loading state */}
                        {isLoading && (
                            <React.Fragment>
                                <ShimmerCard />
                                <ShimmerCard />
                            </React.Fragment>
                        )}
                        {hca ? (
                            <div>
                                {!isLoading && showNurseLine && (
                                    <NurseLine
                                        title={strings.nurseLine.title}
                                        body={strings.nurseLine.body}
                                    />
                                )}
                                {isProgramsCompleted && advice24 && (
                                    <Advice24
                                        setShowAdvice24Dialog={props.setShowAdvice24Dialog}
                                        label={strings.advice24.noCost.label}
                                        title={strings.advice24.careLink.title}
                                        description={strings.advice24.careLink.description}
                                    />
                                )}
                                {!isLoading && findCareUrgentCareNearYou && (
                                    <UrgentCare
                                        title={strings.urgentCare.title}
                                        description={strings.urgentCare.description}
                                        urgentCareUrl={createDeeplink(brand, city, state, "urgentCare")}
                                    />
                                )}
                                {!isLoading && urgentCareAtHome && (
                                    <DispatchHealth
                                        brand={brand}
                                        region={profile.region}
                                        title={strings.dispatchHealth.careLink.title}
                                        description={strings.dispatchHealth.careLink.description}
                                    />
                                )}
                                {!isLoading && hcaBehavioralHealthFindCare && (
                                    <BehavioralHealth
                                        title={strings.behavioralHealth.careLink.title}
                                        description={strings.behavioralHealth.careLink.description}
                                    />                                )}
                                {telehealth && (
                                    <Telehealth
                                        telehealthUrl={telehealth?.url}
                                        title={strings.telehealth.careLink.title}
                                        description={strings.telehealth.careLink.description}
                                    />
                                )}
                            </div>
                        ) : (
                            <div>
                                {isProgramsCompleted && advice24 && (
                                    <Advice24
                                        setShowAdvice24Dialog={props.setShowAdvice24Dialog}
                                        label={strings.advice24.noCost.label}
                                        title={strings.advice24.careLink.title}
                                        description={strings.advice24.careLink.description}
                                    />
                                )}
                                {telehealth && (
                                    <Telehealth
                                        telehealthUrl={telehealth?.url}
                                        title={strings.telehealth.careLink.title}
                                        description={strings.telehealth.careLink.description}
                                    />
                                )}
                                {!isLoading && behavioralHealthFindCare && (
                                    <BehavioralHealth
                                        title={strings.behavioralHealth.careLink.title}
                                        description={strings.behavioralHealth.careLink.description}
                                    />
                                )}
                                {!isLoading && findCareUrgentCareNearYou && (
                                    <UrgentCare
                                        title={strings.urgentCare.title}
                                        description={strings.urgentCare.description}
                                        urgentCareUrl={createDeeplink(brand, city, state, "urgentCare")}
                                    />
                                )}
                                {!isLoading && urgentCareAtHome && (
                                    <DispatchHealth
                                        brand={brand}
                                        region={profile.region}
                                        title={strings.dispatchHealth.careLink.title}
                                        description={strings.dispatchHealth.careLink.description}
                                    />
                                )}
                            </div>
                        )}
                    </ul>
                </div>
            </Card.Content>
        </Card>
    )
}

/**
 * Subcomponent: NurseChat
 * -----------------------------------------------------------------------------
 */
const Advice24: React.FC<{
    readonly setShowAdvice24Dialog: (value: boolean) => void
    readonly label: string
    readonly title: string
    readonly description: string
}> = props => {
    const noCostPillProps: PillProps = {
        label: props.label,
        iconLeading: "check",
        variant: "success",
        size: "large"
    }
    return (
        <div>
            <CareLink
                name="get-care-nurse-chat"
                title={props.title}
                description={props.description}
                regence="reg_nurseline_24_7_turquoise"
                asuris="ahs_advice_24-7"
                bridgespan="bsh_advice_24"
                href="#"
                pills={new Array(noCostPillProps)}
                onClick={() =>
                    props.setShowAdvice24Dialog(true)
                }
                arrowType="chevron-right-sm"
            />
        </div>
    )
}

/**
 * Subcomponent: NurseLine
 * -----------------------------------------------------------------------------
 */
const NurseLine: React.FC<{
    readonly title: string
    readonly body: string
}> = props => {
    return (
        <li className="py-xs">
            <div className="flex items-top align-center items-center">
                <div className="flex justify-center align-center items-center w-lg h-lg rounded-circle dark:text-accent">
                    <Pictogram
                        alt=""
                        regence="reg_nurseline_24_7_turquoise"
                        asuris="reg_nurseline_24_7_turquoise"
                        bridgespan="reg_nurseline_24_7_turquoise"
                        className="m-auto w-lg h-lg"
                    />
                </div>
                <div className="flex-1 px-xs">
                    <div className="text-primary dark:text-accent">
                        <strong className="block mb-xxxs">{props.title}</strong>
                    </div>
                    <body>{props.body}</body>
                </div>
            </div>
        </li>
    )
}

/**
 * Subcomponent: BehavioralHealth
 * -----------------------------------------------------------------------------
 */
const BehavioralHealth: React.FC<{
    readonly title: string
    readonly description: string
}> = props => {
    return (
        <div>
            <CareLink
                name="behavioral_health-card"
                title={props.title}
                description={props.description}
                regence="reg_brain_turquoise"
                asuris="ahs_mental_health"
                bridgespan="bsh_contact_us"
                href={Routes.programsBehavioralHealth()}
                target="_self"
                arrowType="chevron-right-sm"
            />
        </div>
    )
}

/**
 * Subcomponent: Telehealth
 * -----------------------------------------------------------------------------
 */
const Telehealth: React.FC<{
    readonly telehealthUrl?: string
    readonly title: string
    readonly description: string
}> = props => {
    return (
        <div>
            <CareLink
                name="explore-telehealth-resources"
                title={props.title}
                description={props.description}
                regence="reg_telehealth_emerald"
                asuris="ahs_telehealth_1"
                bridgespan="bsh_telehealth_mobile"
                href={props.telehealthUrl}
                target="_self"
                arrowType="chevron-right-sm"
            />
        </div>
    )
}

/**
 * Subcomponent: UrgentCare
 * -----------------------------------------------------------------------------
 */
const UrgentCare: React.FC<{
    readonly title?: string
    readonly description?: string
    readonly urgentCareUrl?: string
}> = props => {
    return (
        <div>
            <CareLink
                name="get-urgent-medical-care-near-you"
                title={props.title}
                description={props.description}
                regence="reg_location_pear"
                asuris="ahs_network"
                bridgespan="bsh_network"
                href={props.urgentCareUrl}
                target="_blank"
                isExternal={true}
                arrowType="arrow-top-right"
            />
        </div>
    )
}

/**
 * Subcomponent: DispatchHealth
 * -----------------------------------------------------------------------------
 */
const DispatchHealth: React.FC<{
    readonly title: string
    readonly description: string
    readonly brand: string
    readonly region:string
}> = props => {
    return (
        <div>
            <CareLink
                name="dispatch_health-card"
                title={props.title}
                description={props.description}
                regence="reg_house_emerald"
                asuris="ahs_home"
                bridgespan="bsh_home"
                href={Routes.dispatchHealthConditions(props.brand, props.region)}
                target="_blank"
                isExternal={true}
                arrowType="arrow-top-right"

            />
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default QuickAccess
