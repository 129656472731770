/**
 * Dashboard Insights
 * -----------------------------------------------------------------------------
 * Note: This is a placeholder component to display hydrated insights
 */
import React from "react"
import {
    useSelector,
    useDispatch,
    actions,
    useFeatures,
} from "store"
import { Button, Card } from "heartwood-component-library"
import { Insight } from "store/insights/types"
import { insightAnalytics, tagEvent } from "utils/analytics"
import Features from "@/utils/features"
import CampaignCard from "./campaign_card"
import InsightCard from "./insight_card"
import ShimmerCard from "../shimmer_card"
import EmptyState from "../empty_state"
import ErrorDisplay from "@/components/error_display"
import { useTranslation } from "react-i18next"
import router from "next/router"
import Routes from "@/router"
import { patientFirstName, showPatientInitials } from "@/utils/patient_initials"
import { currentInsights, getOpportunityIds, isCampaign, publishedCampaign, showInsightItems } from "@/utils/rx/utilities"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const DashboardInsights: React.FC = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()
    const {[Features.RX_PCOMP_03]: showLinkToInsights} = useFeatures()

    /**
     * Application state
     */
    const dispatch = useDispatch()
    const insights = useSelector(state => state.insights)
    const account = useSelector(state => state.account)
    const campaigns = useSelector(state => state.campaigns)

    const [showAllInsights, setShowAllInsights] = React.useState(false)
    const [insightsEventsCompleted, setInsightsEventsCompleted] =
        React.useState(false)

    /**
     * Methods
     */
    const insightInitials = (id: string) => {
        return patientFirstName(insights?.members, id)
    }

    /**
     * Component state
     */
    
    // If there are any family members, we will show initials even if no one has turned on family sharing
    const showInitials = showPatientInitials(insights.members)
    const memberId = account?.profile?.id
    const memberInitials = insightInitials(memberId)

    /**
     * New Insights Items first
     */
    const insightItems = showInsightItems(insights)
    const viewableInsights = insightItems.filter(i => publishedCampaign(i, campaigns))
    const newInsightsItems = viewableInsights?.filter(x => x.isNew)?.map(x => x)
    const oldInsightsItems = viewableInsights?.filter(x => !x.isNew)?.map(x => x)
    const sortedInsightsItems = newInsightsItems.concat(oldInsightsItems)
    const sortedInsightsSize = sortedInsightsItems?.length
    const currentInsightItems = currentInsights(sortedInsightsItems)
    const opportunityIds = getOpportunityIds(insights)

    // Using this state flag to only send tealium events once for insight cards rendering view
    if (
        insights.status === "completed" &&
        !insightsEventsCompleted &&
        typeof globalThis?.utag !== "undefined" &&
        globalThis?.utag !== null
    ) {
        setInsightsEventsCompleted(true)
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (insights.status === "pristine") {
            dispatch(actions.fetchInsights(showLinkToInsights))
        }
    }, [insights.status])

    // DSCLNRX-340 here and on View All
    React.useEffect(() => {
        if (!campaigns.isInitialized && opportunityIds?.length > 0) {
            dispatch(actions.fetchOpportunityCampaigns(opportunityIds))
        }
    }, [insights.status])

    const onInsightCardView = (insight: Insight) => {
        const analytic = insightAnalytics(insight)

        tagEvent({
            tealium_event: "appcard_view",
            page_title: document.title,
            appcard_name: `${analytic.analyticId}_insight_card_view_${analytic.title}`,
            rx_campaign_id: analytic.campaignGuid,
        })
    }

    const onInsightCardClick = (insight: Insight) => {
        const analytic = insightAnalytics(insight)

        tagEvent({
            tealium_event: "appcard_click",
            page_title: document.title,
            appcard_name: `${analytic.analyticId}_insight_card_click_${analytic.title}`,
            rx_campaign_id: analytic.campaignGuid,
        })
        dispatch(actions.clickInsight(insight))

        // resets the Insights bell count using useEffect on insights.status change in actions
    }

    const onLinkClick = () => {
        // eslint-disable-next-line functional/immutable-data
        router.push({
            pathname: Routes.insights(),
            query: { start: "home" }
        })
    }

    const getMembershipId = (id: string) => {
        const family = insights.members?.find(member => member.membershipId === id)
        return family && family.membershipId ? family.membershipId : ""
    }
    /**
     * Define template variables
     */
    const strings = t("dashboard.insights", { returnObjects: true })
    const label = `View all (${currentInsightItems?.length})`

    /**
     * Template
     */
    return (
        <Card name="Insights" radius="lg" className="!py-[32px]">
            <Card.Content className="-mx-xs">
                <div className="flex items-center justify-between mb-xs">
                    <h5 className="mb-none">Insights</h5>
                    {showLinkToInsights ? (
                        <strong>
                            <Button
                                name="insights-page-button"
                                label={label}
                                variant="link"
                                onClick={() => onLinkClick()}
                                className="px-xs"
                            />
                        </strong>
                    ) : (
                        sortedInsightsSize > 3 &&
                        (showAllInsights ? (
                            <strong>
                                <Button
                                    name="show-fewer-insights-button"
                                    iconRight="chevron-up-sm"
                                    label="Show fewer Insights"
                                    variant="link"
                                    onClick={() => setShowAllInsights(false)}
                                    className="px-xs"
                                />
                            </strong>
                        ) : (
                            <strong>
                                <Button
                                    name="show-all-insights-button"
                                    iconRight="chevron-down-sm"
                                    label="Show all Insights"
                                    variant="link"
                                    onClick={() => setShowAllInsights(true)}
                                    className="px-xs"
                                />
                            </strong>
                        ))
                    )}
                </div>

                {insights.status === "error" && (
                    <ErrorDisplay location="insights" type="errorWithoutCTA" />
                )}

                {/* Loading */}
                {insights.status === "pending" && (
                    <React.Fragment>
                        <ShimmerCard />
                        <ShimmerCard />
                    </React.Fragment>
                )}

                {/* Insights */}
                {insights.status === "completed" &&
                    currentInsightItems?.map((insight, index) => {
                        if (!showAllInsights && index >= 3) return null

                        if (!insightsEventsCompleted) onInsightCardView(insight)

                        return (<div key={index} className="flex items-center py-xs border-b border-light-100 dark:border-dark-100 last:border-none">
                            {isCampaign(insight) && (
                                <CampaignCard
                                    key={index}
                                    {...insight}
                                    showInitials={showInitials}
                                    initials={insightInitials(
                                        insight.member.membershipId
                                    )}
                                    onClick={() =>
                                        onInsightCardClick(insight)
                                    }
                                />
                            )}
                            {!isCampaign(insight) && (
                                <InsightCard
                                    key={index}
                                    insight={insight}
                                    showInitials={showInitials}
                                    initials={
                                        insightInitials(
                                            insight.member.membershipId
                                        ) || memberInitials
                                    }
                                    membershipId={
                                        getMembershipId(
                                            insight.member.membershipId
                                        ) || ""
                                    }
                                    onClick={() =>
                                        onInsightCardClick(insight)
                                    }
                                />
                            )}
                        </div>
                    )
                })}

                {/* Empty state */}
                {insights.status === "completed" && !sortedInsightsSize && (
                    <EmptyState label={strings.emptyState.label} />
                )}
            </Card.Content>
        </Card>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default DashboardInsights