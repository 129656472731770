import React from "react"
import { useSelector, useDispatch, actions, useFeatures } from "store"
import Features from "utils/features"
import { Clickable, ExternalLinkModal, Spinner } from "elements"
import { Button, Card, Icon, Pictogram } from "heartwood-component-library"
import WellnessAuthorizationModal from "components/programs/wellness_authorization_modal"
import { getEnvironment } from "@/utils/env"
import router from "next/router"
import { useTranslation } from "react-i18next"

const brandedContent = {
    "regence": {
        header: "Regence Empower\u2122",
        ssoButtonText: "Go to Regence Empower\u2122",
        ssoButtonTitle: "Click to sign in to Regence Empower\u2122",
    },
    "asuris": {
        header: "Asuris Motivate\u2122",
        ssoButtonText: "Sign in to Asuris Motivate\u2122",
        ssoButtonTitle: "Click to sign in to Asuris Motivate\u2122",
    },
    "regence-legacy": {
        header: "Regence Empower\u2122",
        ssoButtonText: "Go to Regence Empower\u2122",
        ssoButtonTitle: "Click to sign in to Regence Empower\u2122",
    },
}


const OnlifeCard: React.FC = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Application state
    */
    const dispatch = useDispatch()
    const { onlife } = useSelector(state => state)
    const brand = useSelector(state => state.app.brand)
    const [showModal, setShowModal] = React.useState(false)
    const buttonText = brand === "regence" ? "Go to Regence Empower\u2122" :
        brand === "asuris" ? "Go to Asuris Motivate\u2122" :
            "View website"

    /**
     * Feature flags
    */
    const {
        [Features.DASHBOARD_CARD_EMPOWER]: isDCE,
    } = useFeatures()

    // DEV/UAT Testing
    const env = getEnvironment()
    const showAll = ["dev", "uat"].includes(env) && document.location.toString().split("?").includes("showAll")

    // Render nothing if all Dashboard Care Empower rules evaluate to false
    if (!isDCE && !showAll) return <React.Fragment />

    /**
   * Lifecycle
   */
    React.useEffect(() => {
        if (!onlife.isOnlifeUserModelDataLoading) {
            dispatch(actions.fetchOnlifeUserModelData())
        }
    }, [])

    /**
     * Define template variables
     */
    const strings = t("dashboard.wellness.onLife.card", { returnObjects: true })
    const content = brandedContent[brand] || {};
    const { earnedIncentives, potentialIncentives } = onlife.incentives
    const incentiveMessage = earnedIncentives === 0 ? strings.incentives.getRewarded :
        earnedIncentives < potentialIncentives ? strings.incentives.keepGoing :
            strings.incentives.greatJob
    const learnMoreUrl = onlife.isMedicare ? "/medicare/well-being" : "/member/well-being"
    const { journeyCardName, incentiveSymbol } = onlife.journey;

    return (
        <Card name="beyondwell-card" radius="lg" data-test="medications"  className="!py-xs">
            <WellnessAuthorizationModal />
            {/* Loading */}
            {onlife.isOnlifeUserModelDataLoading ? (
                <div>
                    <Spinner className="flex justify-center"></Spinner>
                </div>
            ) : (
                <div>
                    <div className="md:flex mdx:block xl:flex md:justify-between items-center md:mb-none mb-xs">
                        <div className="flex flex-1">
                            <Pictogram
                                alt=""
                                regence="reg_empower_pear"
                                asuris="ahs_compass"
                                className="mr-xs mt-xs w-xl h-xl"
                            />
                            <div className="mt-xxs">
                                <strong>{content.header}</strong>
                                <p className="mt-xxxs text-muted">{strings.subtitle}</p>
                            </div>
                        </div>
                        {/* <strong> */}
                        <ExternalLinkModal
                            name={"onlife-leaving-site-modal"}
                            isVisible={showModal}
                            onDismiss={() => setShowModal(false)}
                            onClick={() => dispatch(actions.ssoButtonClicked())}
                        />
                        <Clickable
                            name="onlife-view-button"
                            target="_blank"
                            isExternal={true}
                            onClick={() => setShowModal(true)}>
                            <div className="flex items-center xl:px-xs text-primary-vivid dark:text-accent">
                                <strong className="block mb-xxs pr-xxs">{buttonText}</strong>
                                <Icon type="arrow-top-right" size={24} className="mb-xxs"/>
                            </div>
                        </Clickable>
                    </div>
                    <Card.Content className="mt-xs pt-sm border-t border-muted">
                        {(!onlife.isIncentivesFailure || !onlife.isJourneyFailure || !onlife.isMessageCountFailure || showAll) && (
                            <div className="md:flex md:justify-between md:mb-xxs">
                                {(!onlife.isIncentivesFailure && !!potentialIncentives || showAll) && (
                                    <div className="flex">
                                        <div>
                                            <small className="text-muted">{strings.rewards}</small>
                                            <p>${earnedIncentives} of ${potentialIncentives} possible</p>
                                        </div>
                                    </div>
                                )}    
                                {(!onlife.isJourneyFailure || showAll) && (
                                    <div className="flex md:mb-none mb-sm">
                                        <div>
                                            <small className="text-muted">{strings.nextStep}</small>
                                            <div>
                                                {incentiveSymbol === "$" && (
                                                    <span className="text-primary">$</span>
                                                )} {journeyCardName}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(!onlife.isMessageCountFailure || showAll) && (
                                    <div className="flex mr-sm">
                                        <div>
                                            <small className="text-muted">{strings.messages}</small>
                                            <p>{onlife.messageCount}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Card.Content>
                    <strong>{incentiveMessage}</strong>
                    <p className="mt-xxs text-muted">{strings.body}</p>
                    <Button
                        title={"Click to read about " + content.header}
                        name="onlife-learn-more-link"
                        className="pl-none underline"
                        label={strings.learnMoreButton.label}
                        variant="link"
                        aria-hidden="true"
                        onClick={() => router.push(learnMoreUrl)}
                    />
                </div>
            )}
        </Card>
    )
}

export default OnlifeCard
