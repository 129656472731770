import { AxiosRequestConfig } from "axios";
import React from "react"
import http from "utils/http"
import { Props as StyxProps } from "components/alerts/styx_alert"
import { getAlertDismissalStatusFromCookie } from "@/utils/cookies";

/**
 * Return type of useFetchStyxAlerts custom hook.
 */
type UseFetchStyxAlertsResp =  { readonly styxAlerts: readonly StyxProps[] }

/**
 * Custom hook to retrieve the required alerts from managed content blocks api when component mounts.
 * 
 * @param config The axios config containing details of alert that need to be retrieved.
 * @returns Alerts requested.
 */
const useFetchStyxAlerts = (config: AxiosRequestConfig): UseFetchStyxAlertsResp => {
    // Define state.
    const [styxAlerts, setStyxAlerts] = React.useState<ReadonlyArray<StyxProps>>([])

    /**
     * Fetch alerts from managed content blocks api and set as styxAlerts using setStyxAlerts.
     */
    const fetchAlerts = async (): Promise<void> => {
        // Call the managed content blocks api and get alerts requested in config.
        const { data } = await http.get(`/api/managed-content-blocks`, config)

        // If data available then set as styxAlerts.
        if (data) {
            // Filter out valid alerts.
            const validAlerts = data.filter(({ data: alertData }) => alertData?.alertId?.length && alertData?.isValid)

            // Get all alerts with dismissal status.
            const alerts: readonly StyxProps[] = validAlerts.map((item) =>  {
                // Extract the data.
                const { data: alertData, ...restItem } = item;
                const { alertType, ...restAlertData } = alertData || {};

                // Construct proper alert object from data and return it.
                return {
                    ...restItem,
                    ...restAlertData,
                    type: alertType,
                    // If alert is dismissible then initialize its dismissal status.
                    isDismissed: alertData.isDismissable?
                        getAlertDismissalStatusFromCookie(alertData.alertId, alertType):
                        undefined
                }
            })

            // Set the alerts with dismissible status as styxAlerts.
            setStyxAlerts(alerts)
        }
    }

    // Retrieve the alerts when component is mounted.
    React.useEffect(() => {
        fetchAlerts();
    }, []);

    // Return the styxAlerts.
    return { styxAlerts };
};

export default useFetchStyxAlerts