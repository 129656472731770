import React from "react"
import { Card } from "heartwood-component-library"
import CareLink from "./care_link"
import { useTranslation } from "react-i18next"
import { ExpressScript } from "utils/rx-resources"

/**
 * Component`
 * -----------------------------------------------------------------------------
 */
const ExpressScriptCard: React.FC = () => {

    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Define template variables
     */
    const strings = t("dashboard.expressScript", { returnObjects: true })

    /**
     * Template
    */
    return (
        <Card name="express-script-card" radius="lg" data-test="express-script"  className="my-lg !px-[32px]">
            <div className="flex items-center justify-between mb-xs">
                <h5 className="flex-1 mb-none">{strings.header}</h5>
            </div>
            <ul className="list-none px-none mb-none">
                <CareLink
                    name="express-scripts-card"
                    title={strings.title}
                    description={strings.description}
                    regence="reg_rx_search_pear"
                    asuris="ahs_medication_search"
                    bridgespan="bsh_medication"
                    href={ExpressScript}
                    target="_blank"
                    isExternal={true}
                    arrowType="arrow-top-right"
                />
            </ul>
        </Card>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ExpressScriptCard
