import React from "react"
import { Button, Popup } from "@/elements"

interface props {
    readonly title: string
    readonly message: string
    readonly button1: string
    //eslint-disable-next-line
    readonly callback1?: Function
    readonly isVisible: boolean
    readonly width?: number
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EmailActivationSuccessPopup: React.FC<props> = props => {
    const popupName = `${props.title.replace(/[^\w\s]/gi, ``).toLowerCase()}`
    return (
        <React.Fragment>
            <Popup
                name={popupName}
                isVisible={props.isVisible}
                width={props.width}
            >
                <div>
                    <h5 tabIndex={0}>{props.title}</h5>
                    <div tabIndex={0}>{props.message}</div>
                    <div className="float-right pt-sm">
                        <Button
                            name={`${popupName}-button`}
                            variant="primary"
                            label={props.button1}
                            size="small"
                            className="items-center"
                            onClick={() => props.callback1()}
                        />
                    </div>
                </div>
            </Popup>
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default EmailActivationSuccessPopup
