/**
 * Annual Costs
 * -----------------------------------------------------------------------------
 * Note: Most of this is literally "plop and dropped" from the previous
 * accumulator header banner. I don't know what most of it does, nor
 * will I defend this code in debate. -Hoi
 */
import React from "react"
import Link from "next/link"
import { Card, Button, Donut } from "heartwood-component-library"
import { Popover, Icon, Spinner } from "heartwood-component-library"
import Routes from "@/router"
import { useSelector, useFeatures, useDispatch, actions } from "store"
import Features from "@/utils/features"
import {
    Accumulator,
    DashboardAccumulator,
    DashboardAccumulatorValues,
} from "@/store/accumulators/types"
import { formatCurrency } from "@/utils/currency"
import ErrorDisplay from "@/components/error_display"
import { tagEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly setAccountCostsStep: (value: boolean) => void
    readonly mdx?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const AnnualCosts = (props: Props) => {
    /**
     * Application state
     */
    const accumulators = useSelector(state => state.accumulators)
    const accums_init = accumulators?.status === "completed"
    const accumerrors = accumulators.error

    /**
     * Consumer rules
     */
    const {
        [Features.MEDIGAP]: isMedigap,
        [Features.DASHBOARD_DENTAL_BENEFITS_ACCUMS]: isDentalUSAble,
    } = useFeatures()

    /**
     * Lifecycle: Fetch accumulators and benefits
     */
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (accumulators.status === "pristine") {
            dispatch(actions.fetchAccumulators())
        }
    }, [])
    React.useEffect(() => {
        if (accumulators.status === "completed") {
            // Notify parent "spotlight" whether this step exists
            props.setAccountCostsStep(hasDashboardAccumulators())
        }
    }, [accumulators.status])

    /**
     * Types
     */
    interface AccumulatorBlockItems {
        readonly name: string
        readonly category: string
        readonly tierIndex: number
        readonly accumulator: string
    }

    /**
     * These are the accumulators to show in the dashboard header.
     */
    const orderedListOfAccumulators: ReadonlyArray<AccumulatorBlockItems> = [
        {
            name: "Individual deductible",
            category: "medical",
            tierIndex: 0,
            accumulator: "individualDeductibles",
        },
        {
            name: "Family deductible",
            category: "medical",
            tierIndex: 0,
            accumulator: "familyDeductibles",
        },
        {
            name: "Individual out-of-pocket max",
            category: "medical",
            tierIndex: 0,
            accumulator: "individualOutOfPocketMaxes",
        },
        {
            name: "Family out-of-pocket max",
            category: "medical",
            tierIndex: 0,
            accumulator: "familyOutOfPocketMaxes",
        },
        {
            name: "Individual deductible (Dental)",
            category: "dental",
            tierIndex: 0,
            accumulator: "individualDeductibles",
        },
        {
            name: "Family deductible (Dental)",
            category: "dental",
            tierIndex: 0,
            accumulator: "familyDeductibles",
        },
    ]

    /**
     * These are the accumulators to show in the dashboard header.
     */
    const orderedListOfAccumulatorsUSAble: ReadonlyArray<AccumulatorBlockItems> =
        [
            {
                name: "Individual deductible",
                category: "medical",
                tierIndex: 0,
                accumulator: "individualDeductibles",
            },
            {
                name: "Family deductible",
                category: "medical",
                tierIndex: 0,
                accumulator: "familyDeductibles",
            },
            {
                name: "Individual out-of-pocket max",
                category: "medical",
                tierIndex: 0,
                accumulator: "individualOutOfPocketMaxes",
            },
            {
                name: "Family out-of-pocket max",
                category: "medical",
                tierIndex: 0,
                accumulator: "familyOutOfPocketMaxes",
            },
        ]

    function hasDashboardAccumulators() {
        return (
            !isMedigap &&
            accumulators?.accumulators &&
            Object.keys(accumulators.accumulators).length > 0
        )
    }

    /**
     * Hide (if no accumulators)
     */
    if (accums_init && !hasDashboardAccumulators()) return null

    /**
     * Loading Template
     */
    if (!accums_init && !accumerrors) {
        return (
            <Card
                name="annual-costs-loading-card"
                radius="lg"
                className="text-center"
            >
                <Spinner />
            </Card>
        )
    }

    // Card Id
    const cardId = props.mdx ? "annual-costs-mdx" : "annual-costs"

    /**
     * Template
     */
    return (
        <Card
            id={cardId}
            name="annual-costs-card"
            radius="lg"
            className="!py-md md:!py-sm md:!mx-none"
        >
            <Card.Content className="-mx-xs !px-md !pb-xxs">
                {/* Header */}
                <div className="flex justify-between items-center mb-xs">
                    <h5 className="flex-1 mb-none">Your annual costs</h5>
                    <Button
                        name="view-annual-costs-button"
                        data-test="view-annual-costs-button"
                        label="View more"
                        variant="link"
                        className="px-xxs font-bold"
                        render={Link}
                        href={Routes.claimsAndCosts()}
                    />
                </div>

                {/* Error message */}
                {accumerrors && (
                    <ErrorDisplay location="benefits" type="errorWithCTA" />
                )}

                {/* Content */}
                {!accumerrors && accums_init && (
                    <div className="md:grid md:grid-cols-2 gap-sm">
                        {!isDentalUSAble && (
                            <AccumulatorBlock
                                maxAccumulators={3}
                                orderedListOfAccumulators={
                                    orderedListOfAccumulators
                                }
                            />
                        )}
                        {isDentalUSAble && (
                            <AccumulatorBlock
                                maxAccumulators={3}
                                orderedListOfAccumulators={
                                    orderedListOfAccumulatorsUSAble
                                }
                            />
                        )}
                    </div>
                )}
            </Card.Content>
        </Card>
    )
}

/**
 * Types
 */
interface AccumulatorBlockItems {
    readonly name: string
    readonly category: string
    readonly tierIndex: number
    readonly accumulator: string
}

/**
 * Subcomponent: AccumulatorBlock
 * -----------------------------------------------------------------------------
 */
const AccumulatorBlock: React.FC<{
    readonly maxAccumulators: number
    readonly orderedListOfAccumulators: ReadonlyArray<AccumulatorBlockItems>
}> = props => {
    /**
     * Application state
     */
    const [accumulators, account, app] = useSelector(state => [
        state.accumulators,
        state.account,
        state.app,
    ])
    const brand = app.brand
    const isBridgespan = brand === "bridgespan"

    /**
     * Lifecycle: Fetch benefits accumulators
     */
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (accumulators.status === "pristine") {
            dispatch(actions.fetchAccumulators())
        }
    }, [])

    function hasAccumulatorData(
        accountId: string,
        category: string,
        tierIndex: number,
        accumulator: string,
    ) {
        return (
            accumulators.accumulators[accountId] &&
            accumulators.accumulators[accountId][category] &&
            accumulators.accumulators[accountId][category].accumulatorTiers[
                tierIndex
            ] &&
            accumulators.accumulators[accountId][category].accumulatorTiers[
                tierIndex
            ].accumulatorTierDetails &&
            accumulators.accumulators[accountId][category].accumulatorTiers[
                tierIndex
            ].accumulatorTierDetails[accumulator]
        )
    }

    function getAccumulatorData(
        accountId: string,
        category: string,
        tierIndex: number,
        accumulator: string,
    ) {
        return (
            hasAccumulatorData(accountId, category, tierIndex, accumulator) &&
            accumulators.accumulators[accountId][category].accumulatorTiers[
                tierIndex
            ].accumulatorTierDetails[accumulator]
        )
    }

    // Pick accumulators with data until you reach the maximum number
    function pickAccums(
        orderedListOfAccumulators: ReadonlyArray<AccumulatorBlockItems>,
        maxAccumulators: number,
        accountId: string,
    ) {
        const results = orderedListOfAccumulators.flatMap(accumArgs =>
            mapOrderedListOfAccumulators(accountId, accumArgs),
        )
        const filteredResults = results.filter(
            (item): item is DashboardAccumulator => !!item,
        )
        const slicedArray = filteredResults.slice(0, maxAccumulators)
        return slicedArray
    }

    function mapOrderedListOfAccumulators(
        accountId: string,
        accumArgs: AccumulatorBlockItems,
    ) {
        const accumItems = getAccumulatorData(
            accountId,
            accumArgs.category,
            accumArgs.tierIndex,
            accumArgs.accumulator,
        )
        if (accumItems) {
            // TODO: [DFAS-229] Create way to filter for just in-network deductibles and oop items
            // See the following for the mappings: https://github.com/cambiahealth/benefits-accumulator-service/blob/master/config/benefits-config.yml
            const INNETWORKACCUMS = [
                "1",
                "3",
                "5",
                "828",
                "5500",
                "5175",
                "5025",
                "889",
                "5000",
                "5525",
                "5675",
                "50",
            ]

            const filteredAccumItems = accumItems.filter(accumItem =>
                INNETWORKACCUMS.includes(accumItem.accumAcctNum),
            )
            const results = filteredAccumItems.map(accumItem =>
                mapAccumItems(accumItem, accumArgs.name),
            )
            return results
        }
    }

    function mapAccumItems(accumItem: Accumulator, accumName: string) {
        const accumUsedValueMedical =
            parseFloat(accumItem.accumulatorMedicalAmountMet) || 0.0
        const accumUsedValuePrescription =
            parseFloat(accumItem.accumulatorRxAmountMet) || 0.0
        const accumUsedValueTotal =
            accumUsedValueMedical + accumUsedValuePrescription
        const accumulatorMax = parseFloat(accumItem.accumulatorTotal)
        const accumulatorMaxText =
            0.0 + parseInt(accumItem.accumulatorTotal, 10) === accumulatorMax
                ? parseInt(accumItem.accumulatorTotal, 10)
                : accumulatorMax

        // Accumulator total must exist and be non-zero.
        // Avoid parsing errors by checking that accumUsedValueTotal is a number.
        if (accumItem.accumulatorTotal) {
            // TODO: remove this once [DFAS-230] Update Deductibles to specify if they are individual or family is resolved
            // Checking if Member or Family already exists avoids doubling up due to a back end change before the front end change is made
            const ACCUM_TYPES = {
                "Individual deductible": "Member",
                "Family deductible": "Family",
                "Individual out-of-pocket max": "",
                "Family out-of-pocket max": "",
                "Individual deductible (Dental)": "Member",
                "Family deductible (Dental)": "Family",
            }

            const label =
                !accumItem.memberAccumulatorDescription.startsWith("Member") ||
                !accumItem.memberAccumulatorDescription.startsWith("Family")
                    ? ACCUM_TYPES[accumName] +
                      " " +
                      accumItem.memberAccumulatorDescription
                    : accumItem.memberAccumulatorDescription
            return {
                label: label,
                max: `${formatCurrency(accumulatorMaxText, true)} total`,
                values: [accumUsedValueTotal, accumulatorMax],
            }
        }
    }

    /**
     * Define member account id (or fallback to first accumulator id)
     */
    const accountId = account.profile.id
    const firstAccumId = Object.keys(accumulators.accumulators)[0]
    const hasAccountAccums = accumulators.accumulators[accountId]

    const result = pickAccums(
        props.orderedListOfAccumulators,
        props.maxAccumulators,
        hasAccountAccums ? account.profile.id : firstAccumId,
    )

    /**
     * Template
     */
    return (
        <React.Fragment>
            {result.map(function (accum: DashboardAccumulator, index: number) {
                return (
                    <AccumulatorItem
                        key={index}
                        label={accum.label}
                        max={accum.max}
                        values={accum.values}
                        isBridgespan={isBridgespan}
                    />
                )
            })}
        </React.Fragment>
    )
}

/**
 * Subcomponent: Accumulator
 * -----------------------------------------------------------------------------
 */
const AccumulatorItem: React.FC<{
    readonly label: string
    readonly max: string
    readonly values: DashboardAccumulatorValues
    readonly isBridgespan: boolean
}> = props => {
    /*
     * Methods
     */
    const onPopoverClick = (name: string) => {
        tagEvent({
            tealium_event: "link",
            data_analytics_id: name,
            link_text: name + "-opened",
            link_url: "",
        })
    }

    /**
     * Define template variables
     */
    const percentDone =
        props.values[1] > 0
            ? Math.round((props.values[0] / props.values[1]) * 100)
            : 0
    const amountToGo = formatCurrency(
        props.values[0] > props.values[1]
            ? 0
            : props.values[1] - props.values[0],
    )
    const isDeductible = !props.label.match(/out of pocket/i)
    const label = props.label.trim().replace(/\d+/g, " ").split(/ |\B(?=[A-Z])/).map((word) => word.toLowerCase()).join("-")
    const popoverName = `${label}-info`
    const donutName = `${label}-donut`

    /**
     * Template
     */
    return (
        <div className="text-center mt-md md:mt-none">
            <div
                className="flex justify-center items-center"
                onClick={() => onPopoverClick(popoverName)}
            >
                <strong>{props.label}</strong>
                <Popover
                    name={popoverName}
                    toggle={
                        <Icon
                            type="question-circle"
                            className="text-primary m-xxs"
                        />
                    }
                    content={
                        isDeductible ? (
                            <div>
                                Your deductible is the fixed amount you are
                                required to meet before your health plan begins
                                to pay for covered services in a plan year. Some
                                covered services and medications do not apply
                                toward a deductible.
                            </div>
                        ) : (
                            <div>
                                This is the maximum amount you pay for covered
                                services in a plan year. If this limit is
                                reached, your health plan will pay 100% of all
                                covered health care costs for the remainder of
                                the year.
                            </div>
                        )
                    }
                    className="text-left"
                />
            </div>
            <Donut
                name={donutName}
                title={percentDone + "%"}
                subtitle={`of ${props.max}`}
                values={[percentDone]}
                className="mx-auto mt-xs mb-xxs"
            />
            <small>
                <strong>{amountToGo + " to go"}</strong>
            </small>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default AnnualCosts
