/**
 * Dashboard - Annual Costs
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Card, Button } from "heartwood-component-library"
import Routes from "@/router"
import { ExternalLinkModal } from "@/elements"
import { useTranslation } from "react-i18next"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly mdx?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const AnnualCostsUsable = (props: Props) => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Component state
     */
    const [modal, setModal] = React.useState(false)

    /**
     * Define template variables
     */
    const strings = t("dashboard.annualCostsUsable", { returnObjects: true })
    const cardId = props.mdx ? "annual-costs-mdx" : "annual-costs"

    /**
     * Template
     */
    return (
        <Card id={cardId} name="annual-costs-card" radius="lg" className="!py-sm">
            <Card.Content className="-mx-xs !px-md !pb-xxs">
                {/* Header */}
                <div className="flex justify-between items-center mb-sm">
                    <h5 className="flex-1 mb-none">{strings.card.title}</h5>
                </div>
                {/* Content */}
                <div className="flex">
                    <div className="w-full">
                        <p tabIndex={0}>
                            {strings.card.body}
                        </p>
                        <Button 
                            name="view-dental-benefits"
                            data-test="view-dental-benefits"
                            label={strings.card.button.label}
                            className="px-xxs font-bold !h-fit"
                            iconRight="arrow-top-right"
                            onClick={() => setModal(true)}
                        />
                    </div>
                </div>
            </Card.Content>
            <ExternalLinkModal
                name={"autopay-leaving-modal"}
                isVisible={modal}
                onDismiss={() => setModal(false)}
                href={Routes.usableSSO()}
                target="_blank"
            />
        </Card>
        
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default AnnualCostsUsable
