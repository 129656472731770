/**
 * Image
 * -----------------------------------------------------------------------------
 */
import React from "react"
import * as Types from "heartwood-component-library"
import { useBrand } from "utils/env"

/**
 * Props
 * -----------------------------------------------------------------------------
 */
export interface Props {
    /**
     * Image element props
     */
    readonly regence?: Types.PictogramProps["regence"]
    readonly asuris?: Types.PictogramProps["asuris"]
    readonly bridgespan?: Types.PictogramProps["bridgespan"]
    readonly alt?: string
    readonly className?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const BrandedImage = (props: Props) => {
    /**
     * Define brand
     */
    const brand = useBrand()

    /**
     * Methods
     */
    const defineDirectory = () => {
        if (brand === "regence") return "regence"
        if (brand === "regence-legacy") return "regence"
        if (brand === "asuris") return "asuris"
        if (brand === "aesthetic") return "asuris"
        if (brand === "bridgespan") return "bridgespan"
        // default
        return "regence"
    }

    const defineImage = () => {
        if (brand === "regence") return props.regence
        if (brand === "regence-legacy") return props.regence
        if (brand === "asuris") return props.asuris
        if (brand === "aesthetic") return props.asuris
        if (brand === "bridgespan") return props.bridgespan
        // default
        return  props.regence
    }

    /**
     * Define template variables
     */
    const image = defineImage()
    const directory = defineDirectory()

    /**
     * Template
     */
    return (
        <img
            src={`assets/img/${directory}/${image}.png`}
            alt={props.alt || ""}
            className={props.className || ""}
        />
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default BrandedImage
