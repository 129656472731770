/**
 * GeneralInformation Card
 * -----------------------------------------------------------------------------
 */
import { useTranslation } from "react-i18next"
import React from "react"
import { Card, Pictogram } from "heartwood-component-library"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const GeneralInfoCard = () => {
    const { t: strings } = useTranslation()
    const item = {
        id: "GENERAL-INFORMATION",
        title: strings("jointAdmin.generalInfoTitle"),
        description: strings("jointAdmin.generalInfoBody")
    }

    /**
      * Template
      */
    return (
        <Card
            name={item.id + "-card"}
            className={"lg:col-span-1"}
            radius="sm"
        >
            <div className="flex flex-col h-full">
                <div className="flex justify-between">
                    <Pictogram
                        alt=""
                        regence="reg_apple_pear"
                        size="small"
                    />
                </div>
                <div className="mt-xs">
                    <strong>{item.title}</strong>
                </div>
                <p className="mt-xxxs text-muted">{item.description}</p>
            </div>
        </Card>
    )

}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default GeneralInfoCard
