/**
 * Dashboard
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Layout from "@/components/layout"
import QuickAction from "@/components/dashboard/v2/quick_action"
import RecentClaims from "@/components/dashboard/v2/recent_claims"
import DashboardInsights from "@/components/dashboard/v2/insights/insights"
import DashboardMedications from "@/components/dashboard/v2/medications"
import QuickAccess from "@/components/dashboard/v2/quick_access"
import { useFeatureByName, useFeatures, useSelector } from "store"
import StyxAlert from "components/dashboard/v2/styx_alert"
import DashboardResources from "@/components/dashboard/v2/resources"
import Routes from "@/router"
import PremiumPayment from "@/components/dashboard/v2/premium_payment"
import RenewalBanner from "@/components/dashboard/v2/renewal_banner"
import Features from "@/utils/features"
import JointAdminDashboard from "@/components/dashboard/joint-admin/joint_admin_dashboard"
import EmailValidationPopup from "@/components/account/email_verification_popup"
import { MEMBER_CARDS_PREV_URL_KEY } from "@/store/member-cards/utils"
import NoticeComponent from "@/components/alerts/notice"
import DashboardWellness from "@/components/dashboard/v2/wellness"
// import AnnualCosts from "@/components/dashboard/v2/annual_costs"
import AnnualCostsLegacy from "@/components/dashboard/v2/annual_costs_legacy"
import AnnualCostsUsable from "@/components/dashboard/v2/annual_costs_usable"
import Dashboardlight from "@/components/dashboard/v2/dashboardlight"
import Advice24DialogModal from "@/components/programs/advice24_dialog_modal"
import { useTranslation } from "react-i18next"
import ExpressScriptCard from "@/components/dashboard/v2/express_script"
import NavigationSpotlight from "@/components/dashboard/v2/navigation_spotlight"
import { FRAGMENT_IDS } from "@/utils/styx"
import useFetchStyxAlerts from "@/custom-hooks/useFetchStyxAlerts"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Home = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Feature flags
     */
    const {
        [Features.JOINT_ADMIN_ENABLED]: isJointAdminEnabled,
        [Features.JOINT_ADMIN]: jointAdminFeature,
        [Features.DFW_MY_ACCOUNT_ENABLED]: isDFWMyAccountEnabled,
        [Features.COVERED_SERVICES__CHECK_BENEFITS_QUICK_ACTION]: isBenefitsViewEligible,
        [Features.DASHBOARD__FIND_CARE_CARD]: hasProviderCoverage,
        [Features.DASHBOARD__USABLE_DENTAL_CARD]: showUsableCard,
        [Features.NAV__CLAIMS_COSTS__EOB_STATEMENTS]: isEOBViewEligible,
        [Features.DASHBOARD__BANNER_CONTINUITY_OF_CARE]: showCocBanner,
        [Features.EXPRESS_SCRIPT]: isExpress,
    } = useFeatures()

    /**
     * Application state
     */
    const showSpotlightCard = useFeatureByName(Features.DASHBOARD__SPOTLIGHT_CARD)
    const showAdvice24Card = useFeatureByName(Features.DASHBOARD__ADVICE24_CARD)
    const showResourceListCard = useFeatureByName(Features.DASHBOARD__RESOURCE_LIST_CARD)
    const programs = useSelector(state => state.programs)
    const myAccount = useSelector(state => state.myAccount)

    const advice24Program = programs.items.find(
        program => program.id === "ADVICE_24",
    )
    const [showAdvice24Dialog, setShowAdvice24Dialog] = React.useState(false)
    const [accountCostsStep, setAccountCostsStep] = React.useState(showUsableCard)

    /**
     * Component state
     */
    const [needsEmailVerification, setNeedsEmailVerification] = React.useState(
        myAccount.profile.emailVerificationStatus === "PENDING" &&
        isDFWMyAccountEnabled,
    )

    /**
     * Fetch styx alerts using custom hook
     */
    const LARGE_GROUP_MEMBER_DASHBOARD_ALERTS = "fPoHphGHd3XgaSu7HV1m7G"
    const config = { params: { id: LARGE_GROUP_MEMBER_DASHBOARD_ALERTS } }
    const { styxAlerts } = useFetchStyxAlerts(config)

    /**
     * Define template variables
     */
    const title = t("dashboard.title", { returnObjects: true })
    const strings = t("dashboard.quickActions.v2", { returnObjects: true })
    const features = useFeatures()

    /**
     * Template
     */
    if (isJointAdminEnabled && jointAdminFeature) {
        return <JointAdminDashboard />
    }

    // Determine panel width for spotlight card ids
    const mdx = (window.innerWidth >= 900)
    const claimNavigation=features[Features.NAV__CLAIMS_COSTS__CLAIMS]
    const pharmacyNavigation=features[Features.NAV__YOUR_CARE__MEDICATIONS]
    const careNavigation=features[Features.NAV__YOUR_CARE]
    const showNavigationSpotlight = claimNavigation || pharmacyNavigation || careNavigation

    return (
        <Layout title="Home" noPadding={true}>
            <Dashboardlight
                quickActions={true}
                annualCosts={accountCostsStep}
                quickAccess={showSpotlightCard}
                mdx={mdx}
            />
            {showNavigationSpotlight && (
                <NavigationSpotlight
                    claimNavigation={claimNavigation}
                    pharmacyNavigation={pharmacyNavigation}
                    careNavigation={careNavigation}
                />
            )}

            {/* Header */}
            <div className="bg-primary-300">
                <div className="content pt-lg pb-xl px-sm">
                    <h2 className="text-white text-h4">{title}</h2>

                    {/* Quick Actions */}
                    <div id="quick-actions" className="bg-white md:bg-inherit rounded-lg md:rounded-none md:bg-inherit md:grid grid-cols-4 gap-sm mt-sm mb-xxs py-xs md:py-none">
                        {hasProviderCoverage && (
                            <QuickAction
                                name="quick-action-find-care"
                                data-test="quick-action-find-care"
                                label={strings.findCare.label}
                                regence="reg_stethoscope_pear"
                                asuris="ahs_stethoscope"
                                bridgespan="bsh_search"
                                href={Routes.findCare()}
                                target="_blank"
                                isExternal={true}
                            />
                        )}
                        <QuickAction
                            name="quick-action-member-id"
                            data-test="quick-action-member-id"
                            label={strings.memberId.label}
                            regence="reg_credit_card_emerald"
                            asuris="ahs_member_id"
                            bridgespan="bsh_member_id"
                            href={Routes.memberCards()}
                            onClick={() => {
                                window.sessionStorage.setItem(
                                    MEMBER_CARDS_PREV_URL_KEY,
                                    Routes.home(),
                                )
                            }}
                        />
                        {isBenefitsViewEligible && (
                            <QuickAction
                                name="quick-action-view-benefits"
                                data-test="quick-action-view-benefits"
                                label={strings.benefits.label}
                                regence="reg_bar_graph_emerald"
                                asuris="ahs_which_plan"
                                bridgespan="bsh_be-prepared"
                                href={Routes.checkBenefits()}
                            />
                        )}
                        {isEOBViewEligible && (
                            <QuickAction
                                name="quick-action-view-eob"
                                data-test="quick-action-view-eob"
                                label={strings.eob.label}
                                regence="reg_file_turquoise"
                                asuris="ahs_choose_document_1"
                                bridgespan="bsh_document"
                                href={Routes.eobs()}
                            />
                        )}
                    </div>

                    {/* Renewal Banner */}
                    <RenewalBanner />

                    {/* CoC Notice Banner */}
                    {showCocBanner && <NoticeComponent fragmentId={FRAGMENT_IDS.BENEFITS_LAYOUT} />}

                    {/* Conditional: Payment Banner */}
                    <PremiumPayment />

                    {/* Alerts */}
                    {styxAlerts.map(
                        (alert, index) =>
                            alert?.isValid &&
                            !alert.isDismissed && (
                                <div key={index}>
                                    <StyxAlert
                                        key={`large-group-alerts-${
                                            alert?.alertId || index
                                        }`}
                                        {...alert}
                                    />
                                </div>
                            ),
                    )}
                </div>
            </div>

            {/* Modals */}
            <EmailValidationPopup
                showPopup={needsEmailVerification}
                onDismiss={() => setNeedsEmailVerification(false)}
            />

            {/* Sections */}
            <div className="content mdx:flex mdx:space-x-sm -mt-xxl px-sm mdx:hidden">
                <div className="flex-1 flex flex-col space-y-sm">
                    {showAdvice24Card && <QuickAccess setShowAdvice24Dialog={setShowAdvice24Dialog} />}
                    {isExpress && <ExpressScriptCard />}
                    {!isExpress && <DashboardMedications />}
                    <RecentClaims />
                    {/* <AnnualCosts setAccountCostsStep={setAccountCostsStep} /> */}
                    {showUsableCard ? <AnnualCostsUsable /> : <AnnualCostsLegacy setAccountCostsStep={setAccountCostsStep} />}
                    <DashboardInsights />
                    {showResourceListCard && <DashboardResources />}
                </div>
            </div>
            <div className="content mdx:flex mdx:space-x-sm -mt-xxl px-sm">
                <div className="flex-1 flex flex-col space-y-sm">
                    <div className="hidden mdx:block space-y-sm">
                        <RecentClaims />
                        {isExpress && <ExpressScriptCard />}
                        {!isExpress && <DashboardMedications />}
                        <DashboardInsights />
                        {showResourceListCard && <DashboardResources />}
                    </div>
                </div>
                
                <div className="flex-1 flex flex-col space-y-sm mt-xxs mdx:mt-none">
                    <div className="hidden mdx:block space-y-sm">
                        {/* <AnnualCosts setAccountCostsStep={setAccountCostsStep} /> */}
                        {showUsableCard ? <AnnualCostsUsable mdx={true} /> : <AnnualCostsLegacy setAccountCostsStep={setAccountCostsStep} mdx={true} />}
                        {showAdvice24Card && <QuickAccess setShowAdvice24Dialog={setShowAdvice24Dialog} mdx={true} />}
                    </div>
                    <div className="">
                        {programs && <DashboardWellness />}
                    </div>
                    
                </div>
            </div>
            <Advice24DialogModal
                name={"find-care"}
                cta={advice24Program?.cta}
                isVisible={showAdvice24Dialog}
                onDismiss={() => setShowAdvice24Dialog(false)}
            />
        </Layout>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Home
