import BrandedImage from "@/elements/branded_image"
import { useFeatures } from "store"
import { tagEvent } from "@/utils/analytics"
import Features from "@/utils/features"
import { Button, useModalStore, Modal } from "heartwood-component-library"
import { Spotlight } from "heartwood-component-library/nextjs"
import React from "react"
import { http } from "@/utils"
import { CallBackProps } from "react-joyride"
import { useTranslation } from "react-i18next"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly quickActions: boolean
    readonly annualCosts: boolean
    readonly quickAccess: boolean
    readonly mdx: boolean
}

const Dashboardlight: React.FC<Props> = (props: Props) => {

    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Feature booleans
    */
    const {
        [Features.DASHBOARD_SPOTLIGHT]: isDashboardSpotlight,
        [Features.NAVIGATION_SPOTLIGHT]: isNavigationSpotlight,
    } = useFeatures()

    const [visible, setVisible] = React.useState(false)
    const modal = useModalStore({ open: visible })
    const [start, setStart] = React.useState(false)
    const showSpotlight = isDashboardSpotlight

    React.useEffect(() => {
        const isDismissed = (window.sessionStorage.getItem("spotlight_dismissed") == "true")
        if (showSpotlight && !isDismissed) {
            setVisible(true)
            window.sessionStorage.setItem("spotlight_dismissed", "true")
        }
    }, [])

    const sendSpotlightDismiss = async () => {
        // Ivan's EMS method instead of dispatch(actions.spotlightDismiss())
        const { error, status, data } = await http.put(`/api/ems/feature-flags/user-signals/dismiss/spotlight`, {})
        if(error || status >= 400){
            // cannot use janusLogger in a browser environment
            console.error("Failed to dismiss dashboard spotlight", status, error)
        }
        if (isNavigationSpotlight) {
            const { error: errorNav, status: statusNav, data: dataNav } = await http.put(`/api/ems/feature-flags/user-signals/dismiss/navigation_spotlight`, {})
            if(errorNav || statusNav >= 400){
                // cannot use janusLogger in a browser environment
                console.error("Failed to dismiss navigation spotlight", statusNav, errorNav)
            }
        }
    }

    const onClick = () => {
        setVisible(false)
        setStart(true)
        tagEvent({
            tealium_event: "link",
            data_analytics_id: "dashboard-spotlight-click",
            link_text: "See what's changed",
            link_url: "",
        })
    }

    const onSpotlight = (state: CallBackProps) => {
        // start
        if (state.action == "start" && state.lifecycle == "ready" ) {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "dashboard-spotlight-start",
                link_text: "start",
                link_url: "",
            })
        }
        // next
        else if (state.action == "next" && state.lifecycle == "ready" && state.type !== "tour:end") {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "dashboard-spotlight-next",
                link_text: "next",
                link_url: "",
            })
        }
        // back
        else if (state.action == "prev" && state.lifecycle == "ready" ) {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "dashboard-spotlight-back",
                link_text: "back",
                link_url: "",
            })
        }
        // Close X
        else if (state.action == "skip" && state.status == "skipped" && state.type == "tour:end") {
            sendSpotlightDismiss()
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "dashboard-spotlight-close",
                link_text: "Close",
                link_url: "",
            })
        }
        // Got It
        else if (state.action == "next" && state.status == "finished" && state.type == "tour:end") {
            sendSpotlightDismiss()
            tagEvent({
                tealium_event: "link",
                data_analytics_id: "dashboard-spotlight-got-it",
                link_text: "Got It",
                link_url: "",
            })
        }
    }

    const onDismiss = () => {
        setVisible(false)
        sendSpotlightDismiss()
        tagEvent({
            tealium_event: "link",
            data_analytics_id: "dashboard-spotlight-dismiss",
            link_text: "Dismiss",
            link_url: "",
        })
    }

    /**
     * KeyDown Events
     */
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setVisible(false)
        }
    }

    /**
     * Define template variables
     */
    const strings = t("dashboard.dashboardlight", { returnObjects: true })
    const suffix = props.mdx ? "-mdx" : ""

    /**
     * Conditional Data Steps
     */
    const spotlightSteps=(quickActions: boolean, annualCosts: boolean, quickAccess: boolean, mdx: boolean) => {
        const dataSteps = []
        const position = mdx ? "left-start" : "auto"

        if (quickActions) {
            dataSteps.push(
                {
                    target: "#quick-actions",
                    title: strings.quickActions.title,
                    content: strings.quickActions.content,
                    position: position,
                }
            )
        }
        if (annualCosts) {
            dataSteps.push(
                {
                    target: `#annual-costs${suffix}`,
                    title: strings.annualCosts.title,
                    content: strings.annualCosts.content,
                    position: position,
                }
            )
        }
        if (quickAccess) {
            dataSteps.push(
                {
                    target: `#quick-access${suffix}`,
                    title: strings.quickAccess.title,
                    content: strings.quickAccess.content,
                    position: position,
                }
            )
        }
        return dataSteps
    }
    const steps = spotlightSteps(props.quickActions, props.annualCosts, props.quickAccess, props.mdx)



    /**
     * Paradise Template
     */
    return (
        <div onKeyDown={handleKeyDown}>
            <Spotlight
                data-test="dashboard-spotlight"
                name="dashboard-spotlight"
                start={start}
                steps={steps}
                joyride={{
                    scrollOffset: 160,
                    disableCloseOnEsc: true,
                    disableOverlayClose: true,
                    callback: state => {onSpotlight(state)},
                }}
            />

            <Modal
                data-test="dashboard-spotlight-modal"
                name="dashboard-spotlight-modal"
                store={modal}
                title={strings.modal.title}
                hideTitle={true}
                size="medium"
                autoFocus={false}
            >
                <Modal.Content className="bg-primary-50 -mx-sm -mt-sm rounded-t-lg !px-0 !py-0 overflow-hidden">
                    <BrandedImage
                        regence="regence_whats_new"
                        asuris="asuris_whats_new"
                        bridgespan="bridgespan_whats_new"
                        alt="What's new"
                        className="object-cover h-full w-full"
                    />
                </Modal.Content>
                <Modal.Content className="pt-sm px-0 pb-0 md:px-xxs">
                    <h5 className="py-0">{strings.modal.content.title}</h5>
                    <p className="text-sm sm:text-md mb-2 min-[300px]:mb-4">
                        {strings.modal.content.body}
                    </p>
                    <div className="hidden smx:block">
                        <div className="flex justify-end mb-1 px-0 py-0">
                            <Button
                                data-test="dashboard-dismiss-button"
                                className="ml-xs text-sm sm:text-md"
                                name="dismiss-button"
                                label={strings.button.dismiss.label}
                                variant="link"
                                onClick={onDismiss}
                            />
                            <Button
                                data-test="dashboard-confirm-button"
                                className="ml-xs text-sm sm:text-md"
                                name="confirm-button"
                                label={strings.button.confirm.label}
                                onClick={onClick}
                            />
                        </div>
                    </div>
                    <div className="block smx:hidden">
                        <div className="text-center mb-2 px-0 py-0">
                            <Button
                                data-test="dashboard-confirm-button"
                                className="text-sm sm:text-md"
                                name="confirm-button"
                                label={strings.button.confirm.label}
                                onClick={onClick}
                            />
                        </div>
                        <div className="text-center mb-0 px-0 py-0">
                            <Button
                                data-test="dashboard-dismiss-button"
                                className="text-sm sm:text-md"
                                name="dismiss-button"
                                label={strings.button.dismiss.label}
                                variant="link"
                                onClick={onDismiss}
                            />
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default Dashboardlight
