import React from "react"
import { useSelector, useDispatch, actions } from "store"
import { Button, Input, Link, Markdown, Modal, Spinner } from "elements"

const WellnessAuthorizationModal: React.FC = () => {
    /**
   * Application state
   */
    const dispatch = useDispatch()
    const { onlife } = useSelector(state => state)
    const [signature, setSignature] = React.useState("")

    /**
   * Lifecycle
   */
    React.useEffect(() => {
        dispatch(actions.fetchWellnessAuthorizationContent())
    }, [])

    return (
        <Modal
            name="wellness-auth-modal"
            title="Wellness authorization"
            isVisible={onlife.isShowAuthorizationModal}
            onDismiss={() => dispatch(
                actions.dismissOnlifeAuthorizationModal()
            )}
            doNotDismissOnBodyClick={true}
            size="large"
        >
            <Modal.Content>
                {onlife.isWellnessAuthorizationContentLoading && (
                    <Spinner className="flex justify-center"></Spinner>
                )}
                {!onlife.isWellnessAuthorizationContentLoading && (
                    <React.Fragment>
                        <h4>Authorization to disclose protected health information</h4>
                        <p>To get credit for participating in your employer's wellness program, we need your permission.</p>
                        <Markdown content={onlife.wellnessAuthorizationContent} />
                        <Input name="wellness-authorization-signature-input"
                            className="mt-xs"
                            value={signature}
                            setValue={setSignature}
                            placeholder="E-signature"
                            variant="rounded" />
                        Date: {(new Date()).toLocaleDateString()}
                        <div className="mt-xs">
                            <Button
                                name="wellness-authorization-agree-button"
                                label="I agree"
                                onClick={() => dispatch(actions.updateWellness({ value: true, confirmationText: signature }))}
                                disabled={signature.length < 1}
                            />
                            <Button
                                name="wellness-authorization-decline-button"
                                variant="link"
                                label="I do not agree"
                                onClick={() => dispatch(actions.updateWellness({ value: false, confirmationText: "" }))}
                            />
                        </div>
                        <div className="mt-xs">
                            <Link href="/member/wellness-authorization/"
                                name="wellness-authorization-print-link"
                                target="_blank">Print a copy of this authorization</Link>
                        </div>
                    </React.Fragment>
                )}
            </Modal.Content>
        </Modal>
    )
}

export default WellnessAuthorizationModal