/**
 * Estimate Costs Card
 * -----------------------------------------------------------------------------
 */
import { useTranslation } from "react-i18next"
import React from "react"
import { useFeatures } from "store"
import { Card, Clickable } from "elements"
import { Pictogram } from "heartwood-component-library"
import Features from "@/utils/features"
import { Routes } from "router"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EstimateCostsCard = () => {

    /**
     * Feature Toggle
     */
    const {
        [Features.AMERIBEN]: isAmeriBen,
    } = useFeatures()

    /**
     * Analytics
     */
    const { t: strings } = useTranslation()
    const item = {
        name: "estimate-costs-joint-admin",
        title: strings("jointAdmin.estimateCostsTitle"),
        subtitle: strings("jointAdmin.estimateCostsBody"),
        href: Routes.costEstimateSSO(),
        target: "_blank",
    }
    const analytics = { link_text: item.title }

    if (isAmeriBen) {
        return null;
    }
    /**
      * Template
      */
    return (
        <div className="min-w-[240px] w-full">
            <Clickable
                href={item.href}
                target={item.target}
                className="bg-secondary-50"
                isExternal={true}
                analytics={analytics}
            >
                <Card
                    name={item.name}
                    className="h-full bg-opacity-0"
                >
                    <div className="bg-secondary-50 py-lg text-center w-full h-full">
                        <div className="relative h-[80px] w-[80px] mx-auto flex items-center justify-center rounded-circle bg-white">
                            <Pictogram
                                alt=""
                                regence="reg_calculator_emerald"
                                size="small"
                            />
                        </div>
                        <div className="mt-xs mb-none text-xl">{item.title}</div>
                        <div className="mt-xs text-link underline">
                            {item.subtitle}
                        </div>
                    </div>
                </Card>
            </Clickable>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default EstimateCostsCard
