/**
 * Submit a Claim Card
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Card } from "elements"
import { Pictogram } from "heartwood-component-library"
import { useTranslation } from "react-i18next"
import { Routes } from "router"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const SubmitAClaimCard = () => {
    const { t: strings } = useTranslation()
    const item = {
        id: "SUBMIT-A-CLAIM",
        title: strings("jointAdmin.submitClaimTitle"),
        description: strings("jointAdmin.submitClaimBody"),
        url: Routes.memberClaimSubmit(),
        external: false,
        showExternalMessage: false
    }
    const analytics = { link_text: item.title }

    /**
      * Template
      */
    return (
        <Card
            name={item.id + "-card"}
            href={item.url}
            target={item.external ? "_blank" : ""}
            isExternal={item.showExternalMessage}
            className={"lg:col-span-1"}
            analytics={analytics}
        >
            <div className="flex flex-col h-full">
                <div className="flex justify-between">
                    <Pictogram
                        alt=""
                        regence="reg_info_emerald"
                        size="small"
                    />
                </div>
                <div className="mt-xs">
                    <strong>{item.title}</strong>
                </div>
                <p className="mt-xxxs text-muted">{item.description}</p>
                <div
                    className={
                        !item.url && !item.external
                            ? "hidden"
                            : "underline text-link mt-auto"
                    }
                >
                    {strings("jointAdmin.learnMore")}
                </div>
            </div>
        </Card>
    )

}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default SubmitAClaimCard
