/**
 * Empty State
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Pictogram } from "heartwood-component-library"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly label?: string
    readonly className?: string
}
/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EmptyState: React.FC<Props> = (props) => {

    return (
        <div className={`bg-gray-25 dark:bg-dark-200 rounded-sm py-md px-lg ${props.className}`}>
            <div className="text-center">
                <Pictogram
                    alt=""
                    regence="reg_soup_gray"
                    asuris="ahs_check_grey"
                    bridgespan="bsh_star"
                    className="mx-auto"
                    size="small"
                />
                <p className="text-muted mb-none mt-xs">
                    {props.label || "Nothing to show here yet..."}
                </p>
            </div>
        </div >
    )
}

/**
 * Export Component
 * -----------------------------------------------------------------------------
 */
export default EmptyState
