/**
 * GeneralInformation Card
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Card, Pictogram } from "heartwood-component-library"
import { useTranslation } from "react-i18next"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const WebsiteSupportCard = () => {
    const { t: strings } = useTranslation()
    /**
     * Analytics
     */
    const item = {
        id: "WEBSITE-SUPPORT",
        title: strings("jointAdmin.websiteSupportTitle"),
        description: strings("jointAdmin.websiteSupportBody")
    }

    /**
      * Template
      */
    return (
        <Card
            name={item.id + "-card"}
            className={"lg:col-span-1"}
            radius="sm"
        >
            <div className="flex flex-col h-full">
                <div className="flex justify-between">
                    <Pictogram
                        alt=""
                        regence="reg_piggy_bank_turquoise"
                        size="small"
                    />
                </div>
                <div className="mt-xs">
                    <strong>{item.title}</strong>
                </div>
                <p className="mt-xxxs text-muted">{item.description}</p>
            </div>
        </Card>
    )

}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default WebsiteSupportCard
