/**
 * Programs Utilities
 * -----------------------------------------------------------------------------
 */
import { Programs, Program } from "./reducer"

/**
 * Filter programs by sort keys
 * -----------------------------------------------------------------------------
 */
export const defineSortedPrograms = (
    programs: Programs,
    key: string,
    subKey?: string,
): ReadonlyArray<Program> => {
    const section = programs.sorting?.[key] || []
    const sub = !subKey ? section[0] : section.find(s => s.id === subKey)
    const ids = sub?.sort || []
    const items = ids
        .map(id => programs.items?.find(program => program.id === id))
        .filter(program => !!program?.id)
    return items || []
}

/**
 * If remo health exist, use it as a flag to hide the ESTIMATE_COSTS program from the home page top resources
 *
 * programs: programs return are enabled
 * programIds: Programs to use as flag to replace other programs on the order list
 * orderPrograms: list of top programs in the display order/sort order
 */
export const removeOrderedPrograms = (
    programs: ReadonlyArray<Program>,
    programIds: ReadonlyArray<string>,
    orderPrograms: ReadonlyArray<string>,
    removalProgramIds: ReadonlyArray<string>,
) => {
    // If DISPATCH_HEALTH health exist, use it as a flag to hide the ESTIMATE_COSTS program from the home page top resources
    const hasDispatchHealth = !!programs.filter(i => programIds.includes(i.id))
    return hasDispatchHealth
        ? orderPrograms.filter(id => !removalProgramIds.includes(id))
        : orderPrograms
}

/**
 * Custom Program Ordering - Home
 * -----------------------------------------------------------------------------
 */
export const defineHomeIds = (programs: ReadonlyArray<Program>) => {
    const order = [
        "DISPATCH_HEALTH",
        "ADVANTAGES_DISCOUNTS",
        "BEHAVIORAL_HEALTH",
        "EMPOWER",
        "ESTIMATE_COSTS",
        "PREVENTATIVE_CARE",
        "WA_SUBSTANCE_ABUSE", // only for WA users
        "TELEHEALTH",
        "GENDER_AFFIRMING_CARE", // when WA_SUBSTANCE_ABUSE absent
    ]
    const FlagIds = [
        "DISPATCH_HEALTH",
    ]
    const removalIds = [
        "ESTIMATE_COSTS",
    ]

    const updatedOrder = removeOrderedPrograms(
        programs,
        FlagIds,
        order,
        removalIds,
    )

    const ids = programs
        .filter(i => updatedOrder.includes(i.id))
        .sort((a, b) => updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id))
        .map(i => i.id)
        .slice(0, 6)

    return ids || []
}

/**
 * [Medigap] Custom Program Ordering - Home
 * -----------------------------------------------------------------------------
 */
 export const defineMedigapHomeIds = (programs: ReadonlyArray<Program>) => {
    const order = [
        "DISPATCH_HEALTH",
        "ADVANTAGES_DISCOUNTS",
        "BEHAVIORAL_HEALTH",
        "EMPOWER", // handles both Regence Empower & Asuris Motivate
        "PREVENTATIVE_CARE",
        "WA_SUBSTANCE_ABUSE", // only for WA users
        "TELEHEALTH",
    ]

    const ids = programs
        .filter(i => order.includes(i.id))
        .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
        .map(i => i.id)
        .slice(0, 6)

    return ids || []
}

/**
 * Custom Program Ordering - Find Care
 * -----------------------------------------------------------------------------
 */
export const defineFindCareIds = (programs: ReadonlyArray<Program>) => {
    const order = [
        "ADVANTAGES_DISCOUNTS",
        "BEHAVIORAL_HEALTH",
        "CONDITION_MANAGER",
        "TELEHEALTH",
        "GENDER_AFFIRMING_CARE", // when CONDITION_MANAGER absent
    ]

    const ids = programs
        .filter(i => order.includes(i.id))
        .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
        .map(i => i.id)
        .slice(0, 4)

    return ids || []
}

/**
 * [Medigap] Custom Program Ordering - Find Care
 * -----------------------------------------------------------------------------
 */
 export const defineMedigapFindCareIds = (programs: ReadonlyArray<Program>) => {
    const order = [
        "ADVANTAGES_DISCOUNTS",
        "BEHAVIORAL_HEALTH_MEDIGAP",
        "CONDITION_MANAGER",
        "TELEHEALTH",
        "GENDER_AFFIRMING_CARE", // when CONDITION_MANAGER absent
    ]

    const ids = programs
        .filter(i => order.includes(i.id))
        .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
        .map(i => i.id)
        .slice(0, 4)

    return ids || []
}

export const defineBridgespanFindCareIds = (programs: ReadonlyArray<Program>) => {
    const order = [
        "TELEHEALTH",
        "BUMP2BABY",
        "ADVANTAGES_DISCOUNTS",
        "ESTIMATE_COSTS",
        "BEHAVIORAL_HEALTH"
    ]

    const ids = programs
        .filter(i => order.includes(i.id))
        .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
        .map(i => i.id)
        .slice(0, 4)

    return ids || []
}

/**
 * Custom Program Ordering - Resources
 * -----------------------------------------------------------------------------
 */
export const defineResourceCategoryIds = (programs: ReadonlyArray<Program>) => {
    // 1. Employer-specific programs

    // m3p-wipro specific sso card
    const m3p_wipro_id = "M3P_WIPRO"
    const wipro_programs = programs.filter(i => m3p_wipro_id.includes(i.id))
    
    const employer_ids = ["ASANTE_EMPLOYER_CTA", "WINCO_EMPLOYER_CTA"]
    const employer_programs = programs.filter(i => employer_ids.includes(i.id))

    // 2. Dispatch Health
    const dispatch_id = "DISPATCH_HEALTH"
    const dispatch_program = programs.filter(i => i.id === dispatch_id)

    // 3. Tools, sorted a-z
    const tool_programs = programs
        .filter(i => !employer_ids.includes(i.id))
        .filter(i => i.id !== dispatch_id)
        .filter(i => i.type === "tool")
        .filter(i => i.type !== "large")
        .sort((a, b) => a.title.localeCompare(b.title))

    // 4. Remaining, sorted a-z
    const remaining_programs = programs
        .filter(i => !employer_ids.includes(i.id))
        .filter(i => i.id !== dispatch_id)
        .filter(i => i.type !== "tool")
        .filter(i => i.type !== "large")
        .sort((a, b) => a.title.localeCompare(b.title))

    // 5. Large, sorted a-z
    const large_programs = programs
        .filter(i => !employer_ids.includes(i.id))
        .filter(i => i.type === "large")
        .sort((a, b) => a.title.localeCompare(b.title))

    // 6. Join all the sorted programs
    const sorted_programs = [
        ...wipro_programs,
        ...employer_programs,
        ...dispatch_program,
        ...tool_programs,
        ...remaining_programs,
        ...large_programs
    ]

    // 7. Group programs by category
    return {
        all: sorted_programs
            .filter(i => i.category.includes("All"))
            .map(i => i.id),
        behavioral_health: sorted_programs
            .filter(i => i.category.includes("Behavioral health"))
            .map(i => i.id),
        pregnancy: sorted_programs
            .filter(i => i.category.includes("Pregnancy"))
            .map(i => i.id),
        telehealth: sorted_programs
            .filter(i => i.category.includes("Telehealth"))
            .map(i => i.id),
    }
}
