/**
 * Dashboard - Quick Action
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Clickable from "@/elements/clickable"
import { Card, Icon, Pictogram, PictogramProps } from "heartwood-component-library"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly label: string
    readonly href?: string
    readonly target?: string
    readonly onClick?: VoidFunction
    readonly isExternal?: boolean
    // readonly pictogram: PictogramProps
    readonly regence?: PictogramProps["regence"]
    readonly bridgespan?: PictogramProps["bridgespan"]
    readonly asuris?: PictogramProps["asuris"]
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const QuickAction = (props: Props) => {

    return (
        <Clickable
            name={props.name}
            onClick={() => false}
            className="md:rounded-lg bg-white bg-opacity-0 md:bg-opacity-100"
            href={props.href}
        >
            <Card
                id="quick-action"
                name={props.name + "-card"}
                className="shadow-none"
            >
                <Card.Content className="-my-xs md:my-none px-sm xl:px-sm h-full">

                    <div className="h-full md:flex xl:flex-none flex-col justify-between">

                        <div className="h-full md:h-auto xl:h-full flex items-center justify-start md:justify-between xl:justify-start">
                            <Pictogram
                                alt=""
                                regence={props.regence}
                                asuris={props.asuris}
                                bridgespan={props.bridgespan}
                                className="w-lg h-lg"
                            />
                            <div className="flex-1 px-xs text-link md:hidden xl:block">
                                <strong>{props.label}</strong>
                            </div>
                            <Icon
                                type="chevron-right-sm"
                                className="text-link"
                            />
                        </div>
                        <div className="text-link hidden md:block xl:hidden mt-xs">
                            <strong>{props.label}</strong>
                        </div>
                    </div>
                </Card.Content>
            </Card>
        </Clickable>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default QuickAction