import React from "react"
import { useFeatures } from "store"
import Features from "utils/features"
import { Routes } from "router"
import { Card, Icon } from "heartwood-component-library"
import { getEnvironment } from "@/utils/env"
import { Clickable } from "@/elements"
import { useTranslation } from "react-i18next"

const HingeHealthCard: React.FC = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()
    
    /**
     * Feature flags
    */
    const {
        [Features.RESOURCE_HINGE_HEALTH]: hasHingeHealth,
        [Features.CIS]: isCIS,
    } = useFeatures()

    // DEV/UAT Testing
    const env = getEnvironment()
    const showAll = ["dev", "uat"].includes(env) &&  document.location.toString().split("?").includes("showAll")

    /**
     * Define template variables
     */
    const strings = t("dashboard.wellness.hingeHealth.card", { returnObjects: true })
    
    // Render nothing if all CIS and Hinge Health rules evaluate to false
    if ((!isCIS || !hasHingeHealth) && !showAll) return <React.Fragment />

    return (
        <Card name="beyondwell-card" radius="lg" data-test="medications">
            <Card.Content className="md:flex justify-between items-center">
                <div className="flex flex-1">
                    <div>
                        <strong>{strings.title}</strong>
                        <p className="mt-xxxs text-muted">{strings.subtitle}</p>
                    </div>
                </div>
                <strong>
                    <Clickable
                        name="hinge-health-button"
                        target="_blank"
                        isExternal={true}
                        href={isCIS ? Routes.hingeHealthCIS() : Routes.hingeHealth()}>
                        <div className="flex items-center md:px-xs pb-xxs text-primary-vivid dark:text-accent">
                            <strong className="block mb-xxxs pr-xxs">{strings.goTo}</strong>
                            <Icon type="arrow-top-right" size={24} className="mb-xxs"/>
                        </div>
                    </Clickable>
                </strong>
            </Card.Content>
            <Card.Content className="pt-xs border-t border-muted">
                <div className="pt-xs">
                    <p className="text-muted mb-xxs">{strings.body}</p>
                    <Clickable
                        name="hinge-health-link"
                        target="_blank"
                        isExternal={true}
                        href={isCIS ? Routes.hingeHealthCIS() : Routes.hingeHealth()}
                        className="pl-none"
                        analytics={{
                            data_analytics_id: "hinge-health-link",
                            link_url: isCIS ? Routes.hingeHealthCIS() : Routes.hingeHealth(),
                        }}
                    >
                        <div className="underline text-primary dark:text-accent">
                            {strings.learnMore}
                        </div>
                    </Clickable>
                </div>
            </Card.Content>
        </Card>
    )
}

export default HingeHealthCard
