import { Claim } from "./types"
import { sub, isAfter } from "date-fns"
import {IconTypes, formatCase} from "heartwood-component-library"
import { RecentClaim } from "../recent-claims/types"

/**
 * Helpers
 * -----------------------------------------------------------------------------
 */
export const ClaimStatuses = {
    C: "Completed",
    I: "In-process",
    P: "Pending",
    A: "Adjusted",
}

/**
 * Claim type helper for icons, labels, etc...
 * Ex: "M" = { label: "medical", icon: "stethoscope" }
 * -----------------------------------------------------------------------------
 */
export interface ClaimMeta {
    readonly label: string
    readonly icon: IconTypes
}
const ClaimTypeMeta: Record<Claim["claimType"], ClaimMeta> = {
    M: { label: "medical", icon: "stethoscope" },
    V: { label: "vision", icon: "glasses" },
    R: { label: "rx", icon: "rx" },
    D: { label: "dental", icon: "tooth" },
}
export const getClaimTypeMeta = (claimType?: Claim["claimType"]): ClaimMeta => {
    return ClaimTypeMeta[claimType]
}

/**
 * Filter recent claims
 * -----------------------------------------------------------------------------
 * 1. If pending/in progress AND/OR
 * 2. If processed in past 6 months AND/OR
 * 3. If adjusted in past 6 months THEN
 * 4. Display pending/in progress first THEN
 * 5. Display process or adjusted in past 6 months
 * As part of DSEXPB-5768, the dashboard claims are being changed to show just completed recent claims.
 */
export const filterRecentClaims = (
    claims: ReadonlyArray<Claim>,
    selectedMember?: string,
) => {
    /**
     * Selected member claims (conditional)
     */
    const member = selectedMember
        ? claims.filter(claim => claim.membershipId === selectedMember)
        : claims

    /**
     * Recent claims
     */
    const xMonthsAgo = sub(new Date(), { months: 6 })
    const recent = member.filter(claim => {
        // TODO: Filter on `adjustedDate` as well
        // TODO: Does `adjustedDate` even exist?
        return isAfter(new Date(claim.claimServiceStartDate), xMonthsAgo)
    })

    /**
     * Completed and recent claims
     */
    const completed = recent.filter(claim => claim.claimStatusCode === "C")

    /**
     * Return top three relevant claims
     */
    return completed.slice(0, 3)
}

/**
 * Filter duplicate providers
 */
export const filterDuplicateProviders = (
    items: ReadonlyArray<Claim>,
) => {
    return items
        .map(item => {
            return item.providerName !== null ? item.providerName : ""
        })
        .filter((value, index, array) => {
            return array.indexOf(value) === index && value !== ""
        })
}

/**
 * Get status
 */
export const getStatus = (
    claim: Claim | RecentClaim,
) => {
    const claimStatus = ClaimStatuses[claim.claimStatusCode || "P"]
    const isCompleted = !!claimStatus.match(/completed|adjusted/gi)
    const variant: "default" | "success" | "warning" | "error" | "primary" =
        isCompleted ? "success" : "default"
    const status = {
        label:
            claimStatus.toLowerCase() === "in-process"
                ? formatCase("pending", "capitalize")
                : formatCase(claimStatus, "capitalize"),
        variant: variant,
        inverted: isCompleted ? true : false,
    }
    return status
}

export const getAdjusted = (
    claim: Claim | RecentClaim,
) => {
    const warning: "default" | "success" | "warning" | "error" | "primary" =
    "warning"
    const adjusted = claim.adjusted && {
        label: "Adjusted",
        variant: warning,
        inverted: true,
    }
    return adjusted
}
