/**
 * Premium Payment
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions, useFeatures } from "store"
import Features from "@/utils/features"
import { PaymentBannerv2 } from "components/dashboard/v2/payment_banner"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const PremiumPayment: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Feature flags
     */
    const {
        [Features.DASHBOARD_BANNER__PREMIUM_PAYMENT_V2]: paymentBannerV2,
    } = useFeatures()

    /**
     * Application state
     */
    const [premium] = useSelector(state => [
        state.premiumPayInfo,
    ])

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (!premium?.isInitialized) {
            dispatch(actions.fetchPremiumPayBanner())
        }
    }, [])

    /**
     * Template
     */
    if (paymentBannerV2) {
        return (
            <React.Fragment>
                <PaymentBannerv2 />
            </React.Fragment>
        )
    } else {
        return null
    }
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default PremiumPayment
