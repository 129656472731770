/**
 * Dashboard Insight Card
 * -----------------------------------------------------------------------------
 * Note: This is a placeholder component to display hydrated insights
 */
import React from "react"
import { Insight } from "store/insights/types"
import Routes from "router"
import { Avatar, AvatarStack, Badge, Clickable, Icon } from "heartwood-component-library"
import router from "next/router"
import { tagEvent } from "@/utils/analytics"

interface InsightCardProps {
    readonly insight: Insight
    readonly showInitials: boolean
    readonly initials: string
    readonly onClick?: VoidFunction
    readonly membershipId?: string
}

/**
 * Subcomponent: InsightCard
 * -----------------------------------------------------------------------------
 */
const InsightCard: React.FC<InsightCardProps> = props => {
    /**
     * Template variables
     */
    const insight = props.insight
    const isCareTeamIntro =
        insight.analyticsTitle === "care team intro" && !insight.action.url
    const isTimelineCTA =
        insight.title ===
        "See all of your meds, appointments and health-related details in one place." &&
        !insight.action.url
    const defineActionHref = () => {
        if (isCareTeamIntro) return Routes.providers()
        if (isTimelineCTA) return Routes.timeline()
        const insightUrl = insight.action?.url
        return insightUrl?.includes("care-gaps")
            ? insightUrl +
            (insightUrl?.includes("?") ? "&" : "?") +
            "insight=" +
            insight.insightId
            : insightUrl
    }

    const actionHref = defineActionHref()

    /**
     *  tagging click
     */
    const onClick = () => {
        router.push(actionHref)
        tagEvent({
            tealium_event: "link",
            data_analytics_id: "dashboard-insight-item",
            link_text: "link",
            link_url: actionHref,
        })
        props.onClick()
    }

    /**
     * Template
     */
    return (
        <Clickable 
            datatype={"insight-card-" + insight.insightId}
            className="w-full flex items-center space-x-xs"
            onClick={onClick}
            name={"insight-card-" + insight.insightId}
        >
            {props.insight.isNew && (
                <Badge />
            )}
            {props.showInitials && props.initials?.length > 0 ? (
                <AvatarStack
                    items={[
                        { icon: insight.icon, size: "small" },
                        {
                            label: props.initials,
                            variant: "primary",
                            size: "small",
                        },
                    ]}
                />
            ) : (
                <Avatar icon={insight.icon} size="small" />
            )}
            <div className="flex-1">
                {insight.title}
            </div>
            <div className="flex flex-col justify-center" >
                <Icon
                    type="chevron-right-sm"
                    className="text-link"
                />
            </div>
        </Clickable>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default InsightCard