/**
 * Renewal Banner
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Markdown, Link } from "@/elements"
import { Button, Card, Modal, Pictogram, useModalStore } from "heartwood-component-library"
import { formatDate, toKebabCase } from "@/utils"
import { useTranslation } from "react-i18next"
import { actions, useDispatch, useSelector } from "@/store"
import Routes from "@/router"
import { RenewalInfo } from "@/store/renewal/reducer"
import { ModalStoreProps } from "heartwood-component-library/dist/cjs/components/modal/modal"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Notification {
    readonly notice: string
    readonly title: string
    readonly description: string
    readonly subject: string
    readonly subjectDescription: string
    readonly subjectDescriptionLinkBlock?: {
        readonly firstBlock?: string,
        readonly secondBlock?: string,
    }
    readonly renewalUrl?: {
        readonly chooseDifferentPlan?: string
        readonly shopNewPlan?: string
    }
    readonly optionUrls?: {
        readonly shopNewPlan?: string
        readonly confirmShopPlan?: string
        readonly shopBcbsPlans?: string
        readonly shopMarketplacePlans?: string
    }
    readonly hasIneligibleApplicants?: boolean
}

export interface NotificationProps extends Notification {
    readonly type: "success" | "warning" | "error" | ""
    readonly shopMarketPlaceURL?: string
    readonly isAsuris?: boolean
    readonly isShoppingLinks?: boolean
    readonly notificationName?: string
}

interface IneligibleModalProps {
    readonly modal: ModalStoreProps
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const RenewalBanner: React.FC = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()
    const { t } = useTranslation()

    /**
     * Application state
     */
    const [renewal] = useSelector(state => [state.renewal])
    enum notificationCopies {
        CHOSEN_TO_CANCEL = "chosenToCancel",
        PLAN_CHOSEN = "planChosen",
        PRE_OE_PLAN_RECOMMENDED = "preOePlanRecommended",
        PRE_OE_DISCONTINUED = "preOeDiscontinued",
        PLAN_RECOMMENDED_NO_PLAN_CHOSEN = "planRecommendedNoPlanChosen",
        DISCONTINUED_NO_ALT_PLAN = "discontinuedNoAltPlans",
        EMPTY = "",
    }
    type NotificationType = "success" | "warning" | "error" | ""
    type NotificationTypes = {
        readonly [Key in notificationCopies]: NotificationType
    }
    const notificationTypes: NotificationTypes = {
        chosenToCancel: "warning",
        planChosen: "success",
        preOePlanRecommended: "success",
        preOeDiscontinued: "error",
        planRecommendedNoPlanChosen: "success",
        discontinuedNoAltPlans: "error",
        "": "",
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (renewal.status === "pristine") {
            dispatch(actions.initializeRenewalMRS())
        }
    }, [renewal.status])

    /**
     * Function to determine which notification to display
     */
    const renewalNotificationName = (
        renewalInfo: RenewalInfo
    ): notificationCopies => {
        const renewalInteractionState = renewalInfo?.renewalInteractionState
        const renewalWindow = renewalInfo?.renewalWindow
        const isDiscontinuedCounty = renewalInfo?.isDiscontinuedCounty

        if (!renewalWindow || renewalWindow === "OUTSIDE_ENROLLMENT_PERIOD") {
            return notificationCopies.EMPTY
        }
        if (renewalInteractionState === "CANCELLED") {
            return notificationCopies.CHOSEN_TO_CANCEL
        }
        if (renewalInteractionState === "SUBMITTED") {
            return notificationCopies.PLAN_CHOSEN
        }
        if (renewalWindow === "PREENROLLMENT") {
            if (isDiscontinuedCounty) {
                return notificationCopies.PRE_OE_DISCONTINUED
            }
            return notificationCopies.PRE_OE_PLAN_RECOMMENDED
        }
        if (renewalWindow === "ENROLLMENT") {
            if (isDiscontinuedCounty) {
                return notificationCopies.DISCONTINUED_NO_ALT_PLAN
            }
            return notificationCopies.PLAN_RECOMMENDED_NO_PLAN_CHOSEN
        }
        return notificationCopies.EMPTY
    }

    /**
     * Validate renewal banner eligibility
     */
    const notificationName = renewalNotificationName(renewal.renewalInfo)

    // Don't display renewal notification for non Individual member
    // or if there's no notification banner content to render for display
    if (!renewal.renewalInfo?.isIndividualMember || !notificationName)
        return null

    /**
     * Template variables
     */
    const notificationType = notificationTypes[notificationName]
    const isAsuris = renewal.currentPlan?.brand?.family === "ASUR"
    const brandName = isAsuris ? "Asuris" : "Regence"
    const productName = renewal.renewalInfo?.renewedProduct?.name || ""
    const renewalStrings = t("planRenewalBanner", {
        currentYear: renewal.currentPlan?.currentYear,
        nextYear: renewal.currentPlan?.nextYear,
        openEnrollmentEndDate: formatDate(renewal.currentPlan?.openEnrollmentEndDate, "MMM. d"),
        openEnrollmentStartDate: formatDate(renewal.currentPlan?.openEnrollmentStartDate, "MMM. d"),
        coverageEndDate: renewal.currentPlan?.coverageEndDate,
        renewedProductName: productName,
        brand: brandName,
    })
    const renewalItem = renewalStrings[notificationName] as Notification
    const isCompleted = renewal.status === "completed"
    const isShoppingLinks = !(
        renewal.region !== "id" &&
        renewal.renewalInfo?.renewalWindow === "PREENROLLMENT"
    )

    const determineIneligibleApplicants = () => {
        const ineligible = renewal?.applicants?.filter(a => {
            return a.isEligibleForMedicareByAge
        }) || []
        return ineligible.length > 0
    }

    const hasIneligibleApplicants = determineIneligibleApplicants()

    /**
     * Template
     */
    return (
        isCompleted && renewalItem &&
            <RenewalNotification
                {...renewalItem}
                type={notificationType}
                shopMarketPlaceURL={renewal.renewalInfo?.shopMarketPlaceURL}
                isAsuris={isAsuris}
                isShoppingLinks={isShoppingLinks}
                hasIneligibleApplicants={hasIneligibleApplicants}
                notificationName={notificationName}
            />
    )
}

/**
 * Sub-Component: Renewal Notification
 * -----------------------------------------------------------------------------
 */
const RenewalNotification: React.FC<NotificationProps> = props => {
    /**
     * State
     */
    const [showRenewalIneligibleModal, setShowRenewalIneligibleModal] =
        React.useState(false)

    /**
     * Template variables
     */
    const variants = {
        success: "bg-success-50 text-success-300",
        warning: "bg-warning-50 text-warning-300",
        error: "bg-error-300 text-white",
    }

    const planUrl = {
        shopNewPlan: Routes.renewal(),
        chooseDifferentPlan: Routes.renewal(),
        confirmShopPlan: Routes.renewal(),
        shopBcbsPlans: Routes.bcbsa(),
        shopMarketplacePlans: props.shopMarketPlaceURL
    }

    const renewalUrl = Object.keys(props?.renewalUrl || {})
    const optionUrls = Object.keys(props?.optionUrls || {})
    const modal = useModalStore()

    /**
     * Template
     */
    return (
        <Card name="renewal-banner" disablePadding={true} radius="lg"  className="!py-none !px-none mt-sm">
            <div className="lg:flex pt-sm pb-xxs px-sm">
                <div className="min-w-[48px] mb-xs">
                    <Pictogram
                        alt=""
                        regence={props.notificationName === "planChosen" ? "reg_computer_check_emerald" : "reg_idea_turquoise"}
                        asuris={props.notificationName === "planChosen" ? "ahs_check_green" : "ahs_enrolling"}
                        size="small"
                    />
                </div>
                <div className="flex-1 lg:px-xs">
                    <span className={`rounded-lg px-xxs py-[2px] w-fit text-xs mb-xxs inline-block ${variants[props.type]}`}>
                        {props.notice}
                    </span>
                    <h5 className="flex">
                        {props.title}
                    </h5>
                    <Markdown content={props.description} />
                </div>
                {renewalUrl?.length > 0 && (
                    renewalUrl.map((key, index) => {
                        // when eligible for medicare, block shopping links
                        if (props.hasIneligibleApplicants && (key === "shopNewPlan" || key === "chooseDifferentPlan")) {
                            return <div className="mb-xs" key={index}>
                                <Button
                                    name={toKebabCase(props.renewalUrl[key]) + "-medicare-eligible-button"}
                                    href={undefined}
                                    variant="secondary"
                                    iconRight="chevron-right-sm"
                                    label={props.renewalUrl[key]}
                                    labelClass="font-bold"
                                    size="small"
                                    onClick={() => modal.setOpen(true)}
                                />
                            </div>
                        }

                        return <div className="mb-xs" key={index}>
                            <Button
                                name={toKebabCase(props.renewalUrl[key]) + "-button"}
                                href={planUrl[key]}
                                render={Link}
                                variant="secondary"
                                iconRight="chevron-right-sm"
                                label={props.renewalUrl[key]}
                                labelClass="font-bold"
                                size="small" 
                                children={""}                           
                            />
                        </div>
                    })
                )}
            </div>
            <div className="border-t border-light-100" />
            <div className="pt-sm pb-xxs px-sm">
                <p className="font-bold">
                    {props.subject}
                </p>
                <Markdown content={props.subjectDescription} />
                {props.isShoppingLinks && (
                    <div>
                        {/* for planRecommendedNoPlanChosen */}
                        {optionUrls?.length > 0 && props.notificationName === "planRecommendedNoPlanChosen" && (
                            optionUrls.map((key, index) => {
                                // when eligible for medicare, block shopping links
                                if (props.hasIneligibleApplicants &&
                                    (key === "shopNewPlan" || key === "chooseDifferentPlan")) {
                                    return <div className="mb-xs" key={index}>
                                        <p>
                                            {props.subjectDescriptionLinkBlock.firstBlock}
                                            <Link
                                                name={toKebabCase(props.optionUrls[key]) + "-medicare-eligible-link"}
                                                href="/"
                                                onClick={() => modal.setOpen(true)}
                                            >
                                                {props.optionUrls[key]}
                                            </Link>
                                            {props.subjectDescriptionLinkBlock.secondBlock}
                                        </p>
                                    </div>
                                }

                                return props.isAsuris && key === "shopBcbsPlans"
                                    ? null
                                    : <div className="mb-xs" key={index}>
                                        <p>
                                            {props.subjectDescriptionLinkBlock.firstBlock}
                                            <Link
                                                name={toKebabCase(props.optionUrls[key]) + "-link"}
                                                href={planUrl[key]}
                                            >
                                                {props.optionUrls[key]}
                                            </Link>
                                            {props.subjectDescriptionLinkBlock.secondBlock}
                                        </p>
                                    </div>
                            })
                        )}
                        {/* for !== planRecommendedNoPlanChosen */}
                        {optionUrls?.length > 0 && props.notificationName !== "planRecommendedNoPlanChosen" && (
                            optionUrls.map((key, index) => {
                                // when eligible for medicare, block shopping links
                                if (props.hasIneligibleApplicants &&
                                    (key === "shopNewPlan" || key === "chooseDifferentPlan")) {
                                    return <div className="mb-xs" key={index}>
                                        <Button
                                            name={toKebabCase(props.optionUrls[key]) + "-medicare-eligible-button"}
                                            href={undefined}
                                            variant="secondary"
                                            iconRight="chevron-right-sm"
                                            label={props.optionUrls[key]}
                                            labelClass="font-bold"
                                            size="small"
                                            onClick={() => modal.setOpen(true)}
                                        />
                                    </div>
                                }

                                return props.isAsuris && key === "shopBcbsPlans"
                                    ? null
                                    : <div className="mb-xs" key={index}>
                                        <Button
                                            name={toKebabCase(props.optionUrls[key]) + "-button"}
                                            href={planUrl[key]}
                                            render={Link}
                                            variant="secondary"
                                            iconRight="chevron-right-sm"
                                            label={props.optionUrls[key]}
                                            labelClass="font-bold"
                                            size="small" 
                                            children={""}                                        
                                        />
                                    </div>
                            })
                        )}
                    </div>
                )}
            </div>
            {/* Display a modal before user can proceed with renewal when not eligible */}
            {<IneligibleModal
                modal={modal}
            />}
        </Card>
    )
}

/**
 * Sub-Component: Not Eligible Modal
 * -----------------------------------------------------------------------------
 */
const IneligibleModal = (props: IneligibleModalProps) => {
    /**
      * Hooks
      */
    const { t } = useTranslation()

    /**
      * Define template variables
      */
    const strings = t("planRenewalBanner.renewalIneligibleModal", { returnObjects: true })

    /**
      * Template
      */
    return (
        <React.Fragment>
            {/* Remove renewal progress cache modal */}
            <Modal
                name="remove-renewal-progress-modal"
                store={props.modal}
                title={strings.title}
                radius="sm"
            >
                <div className="mt-xs">
                    <Markdown
                        content={strings.body}
                    />
                </div>
                <div className="flex justify-end mt-xs">
                    <Button
                        name="renewal-ineligible-cancel-btn"
                        label={strings.cancelBtn}
                        onClick={() => props.modal.setOpen(false)}
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default RenewalBanner
