/**
 * Code Input Card
 * ------------------------------------------------------------------------------
 */

import { Input } from "@/elements"

interface Props {
    readonly name: string
    readonly value: string
    readonly setValue: (value: string) => void
    readonly onChange?: (e: React.FormEvent<HTMLInputElement>) => void
    readonly onKeyDown?: (e: KeyboardEvent) => void
    readonly onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void
    readonly customValidation?: boolean
    readonly isValid?: boolean
    readonly isDisabled?: boolean
}
const CodeInput: React.FC<Props> = (props: Props) => {

    return (
        <div className="lg:w-[67px] w-[67px]">
            <Input
                name={props.name}
                className="pr-xxs pb-xxs"
                value={props.value}
                setValue={props.setValue}
                onChange={props.onChange}
                onFocus={() => {
                    document.getElementById(props.name).addEventListener("keydown", props.onKeyDown)
                }}
                onBlur={() => {document.getElementById(props.name).removeEventListener("keydown", props.onKeyDown)}}
                onPaste={props.onPaste}
                type="text"
                maxLength={1}
                variant="rounded"
                size={window.innerWidth < 700 ? "medium" : "large"}
                customValidation={props.customValidation}
                isValid={props.isValid}
                disabled={props.isDisabled}
            />
        </div>
    )
}

export default CodeInput;