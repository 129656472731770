/**
 * Dashboard Resources
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions, useFeatures } from "store"
import EmptyState from "components/dashboard/empty_state"
import OnlifeCard from "@/components/programs/v2/onlife_card"
import SmartHealthCard from "@/components/programs/v2/smarthealth_card"
import HealthyBenefitsCard from "@/components/programs/v2/healthy_benefits_card"
import BeyondWellCard from "@/components/programs/v2/beyondwell_card"
import HingeHealthCard from "@/components/programs/v2/hingehealth_card"
import Features from "utils/features"
import { defineSortedPrograms, removeOrderedPrograms } from "store/programs/utils"
import { Program } from "store/programs/reducer"

const resourceOrderBSH = [
    "DISPATCH_HEALTH",
    "ADVANTAGES_DISCOUNTS",
    "BEHAVIORAL_HEALTH",
    "ESTIMATE_COSTS",
    "GENDER_AFFIRMING_CARE",
    "WA_SUBSTANCE_ABUSE",
    "TELEHEALTH",
]

const FlagIds = [
    "DISPATCH_HEALTH",
]
const removalIds = [
    "ESTIMATE_COSTS",
]

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const DashboardWellness = () => {
    /**
     * Feature flags
     */
    const {
        [Features.HCA_GENERAL]: isHCA,
        [Features.CIS]: isCIS,
        [Features.MEDIGAP]: isMedigap,
    } = useFeatures()

    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const [programs, app] = useSelector(state => [state.programs, state.app])
    const brand = app.brand
    const isBSH = brand === "bridgespan"

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (programs.status === "pristine") {
            dispatch(actions.fetchPrograms())
        }
    }, [])

    const sortResources = (programs: ReadonlyArray<Program>, order: ReadonlyArray<string>) => {
        //eslint-disable-next-line
        let newOrder = []
        order.filter(i => programs.filter(o => {if(i === o.id) newOrder.push(o)}))
        return newOrder
    }

    const bshPrograms = programs.items.filter(program => program.category.includes("BSH"))

    const updatedResourceOrderBSH = removeOrderedPrograms(
        bshPrograms,
        FlagIds,
        resourceOrderBSH,
        removalIds,
    )

    /**
     * Define template variables
     */
    const isLoading = programs.status === "pending"
    const items = isHCA ? programs.items.filter(program => program.category.includes("Hca")) :
                  isCIS ? programs.items.filter(program => program.category.includes("Cis")) :
                  isBSH && !isMedigap ? sortResources(bshPrograms, updatedResourceOrderBSH) :
                  defineSortedPrograms(programs, "home")

    const hasItems = !isLoading && items.length

    /**
     * Template
     */
    return (
        (!!items.length || isLoading) && (
            <React.Fragment >
                <BeyondWellCard />
                <SmartHealthCard />
                <OnlifeCard />
                <HealthyBenefitsCard />
                <HingeHealthCard />
                {/* Empty state */}
                {!hasItems && !isLoading && <EmptyState />}
            </React.Fragment>
        ))
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default DashboardWellness
