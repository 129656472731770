import React from "react"
import { Link } from "@/elements"
import { Icon, IconTypes, Pictogram, PictogramProps, Pill, PillProps } from "heartwood-component-library"

/**
 * Component: CareLink
 * -----------------------------------------------------------------------------
 */
const CareLink: React.FC<{
    readonly name: string
    readonly title: string
    readonly description?: string
    readonly icon?: IconTypes
    readonly href: string
    readonly target?: string
    readonly regence: PictogramProps["regence"]
    readonly asuris: PictogramProps["asuris"]
    readonly bridgespan: PictogramProps["bridgespan"]
    readonly onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    readonly isExternal?: boolean
    readonly pills?: readonly PillProps[]
    readonly arrowType?: IconTypes
}> = props => {

    /***
     * Define template variables
     */
    const pill = props.pills && props.pills[0]

    /**
     * Methods
     */
    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
            // TODO: Send analytics event
            onClick(event)
            // Stop the modal from jumping to the top of the page
            event.preventDefault()
        }
    }
    const { onClick } = props

    return (
        <li className="py-xs">
            <Link
                name={props.name}
                href={props.href}
                target={props.target}
                unstyled={true}
                onClick={handleOnClick}
                isExternal={props.isExternal}
                analytics={{
                    data_analytics_id: props.name,
                    link_text: props.title,
                    link_url: props.href,
                }}
            >
                <div className="flex items-top align-center items-center">
                    <div className="flex justify-center align-center items-center w-lg h-lg rounded-circle dark:text-accent">
                        <Pictogram
                            alt=""
                            regence={props.regence}
                            asuris={props.asuris}
                            bridgespan={props.bridgespan}
                            className="m-auto w-lg h-lg"
                        />
                    </div>
                    <div className="flex-1 px-xs">
                        <div className="text-primary dark:text-accent">
                            <strong className="block mb-xxxs">{props.title}</strong>
                        </div>
                        {props.pills && (
                            <div className="my-xxs">
                                {pill && (
                                    <Pill
                                        label={pill.label}
                                        iconLeading={pill.iconLeading}
                                        variant={pill.variant}
                                        size={pill.size}
                                    />
                                )}
                            </div>
                        )}
                        {props.description && (
                            <body>
                                {props.description}
                            </body>
                        )}
                    </div>
                    <Icon
                        type={
                            props.arrowType
                        }
                        className={
                            "text-primary dark:text-accent"
                        }
                    />
                </div>
            </Link>
        </li>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default CareLink
