import { Clickable } from "@/elements"
import { getEnvironment } from "@/utils/env"
import { Card, Icon } from "heartwood-component-library"
import React from "react"
import { useTranslation } from "react-i18next"
import { useFeatures } from "store"
import Features from "utils/features"

const SmartHealthCard: React.FC = () => {
    /**
     * Hooks
     */
    const { t } = useTranslation()
    
    /**
     * Feature flags
    */
    const {
        [Features.HCA_SMARTHEALTH_EARNED]: earned,
        [Features.HCA_SMARTHEALTH_NOT_EARNED]: notEarned,
        [Features.HCA_SMARTHEALTH_OTHER]: other,
    } = useFeatures()

    // DEV/UAT Testing
    const env = getEnvironment()
    const showAll = ["dev", "uat"].includes(env) &&  document.location.toString().split("?").includes("showAll")

    /**
     * Define template variables
     */
    const strings = t("dashboard.wellness.smartHealth.card", { returnObjects: true })

    // Render nothing if all three SmartHealth rules evaluate to false
    if (!earned && !notEarned && !other && !showAll) return <React.Fragment />

    return (
        <Card name="beyondwell-card" radius="lg" data-test="medications">
            <Card.Content className="xl:flex justify-between items-center">
                <div className="md:flex flex-1 items-center">
                    <img
                        src="/assets/img/smarthealth-logo.svg"
                        className={"h-xs mr-sm"} 
                        alt="SmartHealth logo" />
                    <div className="md:mt-none mt-xs">
                        <strong>{strings.title}</strong>
                        <p className="mt-xxxs text-muted">{strings.subtitle}</p>
                    </div>
                </div>
                <Clickable
                    name="smarthealth-button"
                    target="_blank"
                    isExternal={true}
                    href="https://smarthealth.hca.wa.gov/BrandedLogin.aspx?e=WASMARTHealth">
                    <div className="flex items-center xl:px-xs text-primary-vivid dark:text-accent">
                        <strong className="block mb-xxs pr-xxs">{strings.goTo}</strong>
                        <Icon type="arrow-top-right" size={24} className="mb-xxs"/>
                    </div>
                </Clickable>
            </Card.Content>
            <Card.Content className="pt-xs border-t border-muted">
                {!other && (
                    <div className="grid grid-flow-col auto-cols-max">
                        <div className="ml-s">
                            <div className="mt-xxs text-muted">{strings.credit}</div>
                            <div className="mt-xxs">{earned && "Earned"}</div>
                            <div className="mt-xxs">{notEarned && "Not earned"}</div>
                        </div>
                    </div>
                )}
                <div className={!other && "pt-xs"}>
                    <strong>{strings.bodyHeading}</strong>
                    <p className="mt-xxs text-muted mb-xs">{strings.body}</p>
                    <Clickable
                        name="smarthealth-link"
                        target="_blank"
                        isExternal={true}
                        href="https://smarthealth.hca.wa.gov/BrandedLogin.aspx?e=WASMARTHealth"
                        className="pl-none"
                        analytics={{
                            data_analytics_id: "beyondwell-link",
                            link_url: "https://smarthealth.hca.wa.gov/BrandedLogin.aspx?e=WASMARTHealth",
                        }}
                    >
                        <div className="underline text-primary dark:text-accent">
                            {strings.signIn}
                        </div>
                    </Clickable>
                </div>
            </Card.Content>
        </Card>
    )
}

export default SmartHealthCard