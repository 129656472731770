import Routes, { useRouter } from "@/router"
import {
    getAdjusted,
    getClaimTypeMeta,
    getStatus,
} from "@/store/claims/utils"
import {
    Avatar,
    AvatarStack,
    Button,
    Card,
    Icon,
    Pill,
    formatCase,
} from "heartwood-component-library"
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import EmptyState from "./empty_state"
import { formatDate } from "@/utils"
import { Clickable } from "@/elements"
import { RecentClaim } from "@/store/recent-claims/types"
import ShimmerCard from "./shimmer_card"
import ErrorDisplay from "@/components/error_display"
import { useTranslation } from "react-i18next"
import { showPatientInitials } from "@/utils/patient_initials"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const RecentClaims = () => {
    /**
     * Application state
     */
    const dispatch = useDispatch()
    const [claims, recentClaims] = useSelector(state => [
        state.nativeClaims,
        state.recentClaims,
    ])

    /**
     * Hooks
     */
    const router = useRouter()
    const { t } = useTranslation()

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (recentClaims?.status === "pristine") {
            dispatch(actions.fetchRecentClaims())
        }
    }, [])

    React.useEffect(() => {
        if (claims?.status === "pristine") {
            dispatch(actions.fetchNativeClaims())
        }
    }, [])

    /**
     * Methods
     */
    const onClick = () => {
        router.push(Routes.claims())
    }

    /**
     * Define template variables
     */
    const filtered = recentClaims.items
    const strings = t("dashboard.claims", { returnObjects: true })


    /**
     * Template
     */
    return (
        <Card name={"recent-claims-card"} radius="lg" className="!py-[32px]">
            <Card.Content className="-mx-xs">
                <div className="flex items-center mb-xs">
                    <h5 className="flex-1 mb-none">{strings.card.title}</h5>
                    <div>
                        <strong>
                            <Button
                                name="see-claims-button"
                                data-test="see-claims-button"
                                label={strings.card.viewMoreButton.label}
                                variant="link"
                                onClick={() => onClick()}
                                className="px-xs"
                                data-analytics-id="see-claims-button"
                                data-analytics-event="dfwEvent"
                            />
                        </strong>

                    </div>
                </div>
                <div>
                    {/* Loading */}
                    {recentClaims?.status === "pristine" && (
                        <React.Fragment>
                            <ShimmerCard/>
                            <ShimmerCard/>
                        </React.Fragment>
                    )}

                    {/* Claims */}
                    {filtered.map((claim, index) => (
                        <Claim claim={claim} key={index} data-test="recent-claim"/>
                    ))}

                    {/* Error state */}
                    {recentClaims?.status === "error" && (
                        <ErrorDisplay location="claims" type="errorWithCTA" />
                    )}

                    {/* Empty state */}
                    {recentClaims?.status === "completed" && !filtered.length && <EmptyState label={strings.emptyState.label} />}
                </div>
            </Card.Content>
        </Card>
    )
}

/**
 * Subcomponent: Claim
 * -----------------------------------------------------------------------------
 */

const Claim: React.FC<{
    readonly claim: RecentClaim
}> = props => {
    /**
     * Application state
     */
    const [nativeClaims] = useSelector(state => [state.nativeClaims])

    /**
     * Hooks
     */
    const router = useRouter()

    /**
     * Methods
     */
    const onClick = (href:string) => {
        router.push(href)
    }

    /**
     * Define template variables
     */
    const claim = props.claim
    const ClaimTypeMeta = getClaimTypeMeta(claim.claimType)
    const displayName =
        formatCase(claim.providerName, "titlecase") ===
            "Na A No Provider Found" && claim.claimType === "R"
            ? formatCase(claim.pharmacyName, "titlecase")
            : formatCase(claim.providerName, "titlecase")
    const date = formatDate(
        claim.claimServiceStartDate || claim.receivedDate,
        "MM/dd/yyyy",
    )
    const status = getStatus(claim)
    const adjusted = getAdjusted(claim)
    const isPending = claim.claimStatusCode === "P"
    const isInProcess = claim.claimStatusCode === "I"
    const href =
        !isPending && !isInProcess
            ? Routes.claimDetailDF(claim.claimNumberHash)
            : ""
    const firstName = formatCase(claim?.firstName, "titlecase")
    const showInitials = showPatientInitials(nativeClaims.claimFilterData?.memberFilterValues?.filterValues)

    /**
     * Template
     */
    return (
        <Clickable
            name="claim-detail-link"
            data-test="claim-detail-link"
            href={href}
            onClick={() => onClick(href)}
            className="flex items-center w-full py-xs border-b border-light-100 dark:border-dark-100 last:border-none"
        >
            {showInitials && firstName?.length > 0 ? (
                <AvatarStack
                    items={[
                        {
                            icon: ClaimTypeMeta.icon,
                            size: "small",
                        },
                        {
                            label: firstName,
                            variant: "primary",
                            size: "small",
                        },
                    ]}
                />
            ) : (
                <Avatar icon={ClaimTypeMeta.icon} size="small" />
            )}
            <div className="md:flex flex-col md:flex-row w-full md:items-center">
                <div className="flex-1 ml-xxs md:mb-none">
                    <strong className="block mb-xxxs">{displayName}</strong>
                    <p className="mb-xxxs">Visited on {date}</p>
                </div>
                {status && (
                    <Pill
                        label={status.label}
                        size="large"
                        variant={status.variant}
                        inverted={status?.inverted}
                        className="ml-xxs"
                    />
                )}
                {adjusted && (
                    <Pill
                        label={adjusted.label}
                        size="large"
                        variant={adjusted.variant}
                        inverted={adjusted?.inverted}
                        className="ml-xxs"
                    />
                )}
            </div>
            <div className="flex flex-col justify-center ml-xxs">
                <Icon type="chevron-right-sm" className="text-primary-200"/>
            </div>
        </Clickable>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default RecentClaims
