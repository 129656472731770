import React from "react"
import { Button, Popup } from "elements"
import { useDispatch, useSelector, actions } from "store"
import parse from "html-react-parser"
import EmailActivationSuccessPopup from "../account-settings/modals/email_activation_success_modal"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import Routes from "router"
import CodeInput from "../account-settings/account_information/code_input"
import EmailCardPopup from "../account-settings/modals/email_card_popup"

interface Props {
    readonly showPopup: boolean
    //eslint-disable-next-line
    readonly onDismiss: Function
}

const EmailValidationPopup: React.FC<Props> = ({ showPopup, onDismiss }) => {
    const [myAccount] = useSelector(state => [state.myAccount])
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const emailVerificationMessages = t(
        "myAccount.accountInformation.emailVerificationMessages",
        { obfuscatedEmail: obfuscatedEmail() },
    )

    const [messages] = React.useState(emailVerificationMessages)
    const [message] = React.useState(messages.instructions)
    const [showSuccessPopup, setShowSuccessPopup] = React.useState(false)
    const [onboardingInit, setOnboardingInit] = React.useState(false)

    const [isVerificationCodeLoading, setIsVerificationCodeLoading] = React.useState(false)
    const [isEmailLoading,] = React.useState(false)
    const [emailResent, setEmailResent] = React.useState(false)
    const [verificationCodeAttempts, setVerificationCodeAttempts] = React.useState(0)

    const [successfulVerification, setSuccessfulVerification] = React.useState(false)
    const [showMaximumAttemptsError, setShowMaximumAttemptsError] = React.useState(false)
    const [showUnknownError, setShowUnknownError] = React.useState(false)
    const [showIncorrectOrExpiredError, setShowIncorrectOrExpiredError] = React.useState(false)
    const [verificationCodes, setVerificationCodes] = React.useState(["","","","","",""])
    const numOfCodeInputs = 6

    const popupHeight = "h-full"

    const router = useRouter()

    function activationSuccess() {
        dispatch(actions.setEmailVerificationStatus("VERIFIED"))
        const preferences = myAccount?.profile?.preferences?.communicationPreferences
        if (!preferences) {
            // if preferences are null, need to show success modal here because onboarding will be next
            setShowSuccessPopup(true)
        } else {
            router.push(Routes.accountInformationSuccess())
            onDismiss()
        }
    }

    React.useEffect(() => {
        if (myAccount?.status === "pristine") {
            dispatch(actions.fetchMemberSettings())
            dispatch(actions.fetchConsumerSettings())
        }
    }, [])

    React.useEffect(() => {
        if (onboardingInit) {
            // re-initializing the app will send user to onboarding
            dispatch(actions.initialize())
        } 
    }, [onboardingInit])

    function obfuscatedEmail() {
        // const emailToObfuscated = useNewEmail ? email : myAccount.profile.email
        const emailToObfuscated = myAccount.profile.email || ""
        const index = emailToObfuscated.indexOf("@")
        // eslint-disable-next-line
        let emailHead = Array.from(emailToObfuscated)[0]
        const emailTail = emailToObfuscated.slice(index)
        // eslint-disable-next-line
        for (let i = 1; i < index; i++) {
            emailHead = emailHead + "*"
        }
        return emailHead + emailTail
    }

    async function resendEmail() {
        setVerificationCodeAttempts(0)
        setShowMaximumAttemptsError(false)
        setShowIncorrectOrExpiredError(false)
        setShowUnknownError(false)
        setVerificationCodes(["","","","","",""])

        const response = await dispatch(
            actions.updateEmail({email: myAccount.profile.email}),
        )

        if (response?.payload?.data?.errors) {
            setIsVerificationCodeLoading(false)
            setShowUnknownError(true)
        }
        
        if (!response) {
            // if not success response, need to show success modal here because onboarding will be next
            setEmailResent(true)
        } else {
            router.push(Routes.accountInformationResent())
            onDismiss()
        }

    }

    const confirmVerificationCode = async () => {
        setIsVerificationCodeLoading(true)
        const code = verificationCodes.join("")

        //call to verify code
        const response = await dispatch(
            actions.sendEmailActivationCode({activationCode: code}),
        )
        if (response?.payload?.data?.data?.activateEmail?.success) {
            dispatch(actions.updateMyAccountProfileEmail({ email: myAccount.profile.email }))
            setIsVerificationCodeLoading(false)
            setShowIncorrectOrExpiredError(false)
            setShowMaximumAttemptsError(false)
            setShowUnknownError(false)
            //change border color to green for a few seconds and then go back to start of flow with delete phone number showing and new number
            setSuccessfulVerification(true)
            setVerificationCodeAttempts(0)
            activationSuccess()
        } else if (response?.payload?.data?.errors) {
            const attempts = verificationCodeAttempts + 1
            setIsVerificationCodeLoading(false)
            setVerificationCodeAttempts(attempts)
            //show attempts error if max attempts have been reached
            if (attempts === 3) {
                setShowMaximumAttemptsError(true)
            } else {
                setShowIncorrectOrExpiredError(true)
            }
        } else {
            setIsVerificationCodeLoading(false)
            setShowUnknownError(true)
        }
    }

    const handleChange = (e) => {
        const { maxLength, value, name } = e.target
        const [fieldName, fieldIndex] = name.split("_")
        const text = value.replace(/[^\d]/g, "")
        setShowIncorrectOrExpiredError(false)
        setShowUnknownError(false)

        if (text.length >= maxLength) {
            // checking if it's not the last input
            if (parseInt(fieldIndex, 10) < numOfCodeInputs) {
                // get next code input
                const nextSibling = document.querySelector(
                    `input[name=${fieldName}_${parseInt(fieldIndex, 10) + 1}]`
                ) as HTMLInputElement

                if (nextSibling !== null) {
                    nextSibling.focus()
                    nextSibling.select()
                }
            }
        }
        const updatedCodes = verificationCodes.map((currentValue, index, array) => {
            return index === (fieldIndex - 1) ? text : array[index]
        })
        setVerificationCodes(updatedCodes)
    }

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            const { value, name } = e.target
            const [fieldName, fieldIndex] = name.split("_")

            if (value.length === 0) {
                if (parseInt(fieldIndex, 10) > 1) {
                    const previousSibling = document.querySelector(
                        `input[name=${fieldName}_${parseInt(fieldIndex, 10) - 1}]`
                    ) as HTMLInputElement

                    if (previousSibling !== null) {
                        previousSibling.focus()
                    }
                }
            }
        }
    }

    // paste the copied code into the inputs correctly
    const handlePaste = (e) => {
        e.preventDefault()
        if (!showMaximumAttemptsError) {
            const pasted = e.clipboardData.getData("text/plain")
            const code = pasted.replace(/[^\d]/g, "")
            const array = code.split("").slice(0, verificationCodes.length)
            setVerificationCodes(array)
        }
    }
    
    return (
        <div>
            <EmailActivationSuccessPopup
                title={"Success!"}
                message={"Your email address has been successfully updated."}
                callback1={() => {
                    setShowSuccessPopup(false)
                    setOnboardingInit(true)
                    onDismiss()
                }}
                button1={"Done"}
                isVisible={showSuccessPopup}
            />
            <EmailCardPopup
                title={"Sent!"}
                message={"message resent success"}
                callback1={() => {
                    setEmailResent(false)
                    setOnboardingInit(true)
                    onDismiss()
                }}
                button1={"Done"}
                isVisible={emailResent}
            />
            <Popup
                name="email-verification-modal"
                isVisible={showPopup && !showSuccessPopup}
                width={400}
            >
                <div>
                    <div
                        id="email-verification-modal"
                        className={
                            "flex flex-col lg:w-[435px] md:w-[435px] sm:w-[400px]" +
                                popupHeight
                        }
                    >
                        <div>
                            <h5>{message.title}</h5>{" "}
                            <div className="mb-[5%]">
                                {parse(message.message)}
                            </div>
                            <div className="flex flex-wrap mb-xxs">
                                <CodeInput
                                    name="account-information-email-verification-code_1"
                                    value={verificationCodes[0]}
                                    setValue={() => {
                                        return false
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    customValidation={successfulVerification || showMaximumAttemptsError || showIncorrectOrExpiredError}
                                    isValid={successfulVerification}
                                    isDisabled={showMaximumAttemptsError}
                                />
                                <CodeInput
                                    name="account-information-email-verification-code_2"
                                    value={verificationCodes[1]}
                                    setValue={() => {
                                        return false
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    customValidation={successfulVerification || showMaximumAttemptsError || showIncorrectOrExpiredError}
                                    isValid={successfulVerification}
                                    isDisabled={showMaximumAttemptsError}
                                />
                                <CodeInput
                                    name="account-information-email-verification-code_3"
                                    value={verificationCodes[2]}
                                    setValue={() => {
                                        return false
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    customValidation={successfulVerification || showMaximumAttemptsError || showIncorrectOrExpiredError}
                                    isValid={successfulVerification}
                                    isDisabled={showMaximumAttemptsError}
                                />
                                <CodeInput
                                    name="account-information-email-verification-code_4"
                                    value={verificationCodes[3]}
                                    setValue={() => {
                                        return false
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    customValidation={successfulVerification || showMaximumAttemptsError || showIncorrectOrExpiredError}
                                    isValid={successfulVerification}
                                    isDisabled={showMaximumAttemptsError}
                                />
                                <CodeInput
                                    name="account-information-email-verification-code_5"
                                    value={verificationCodes[4]}
                                    setValue={() => {
                                        return false
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    customValidation={successfulVerification || showMaximumAttemptsError || showIncorrectOrExpiredError}
                                    isValid={successfulVerification}
                                    isDisabled={showMaximumAttemptsError}
                                />
                                <CodeInput
                                    name="account-information-email-verification-code_6"
                                    value={verificationCodes[5]}
                                    setValue={() => {
                                        return false
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onPaste={handlePaste}
                                    customValidation={successfulVerification || showMaximumAttemptsError || showIncorrectOrExpiredError}
                                    isValid={successfulVerification}
                                    isDisabled={showMaximumAttemptsError}
                                />
                            </div>
                            {showIncorrectOrExpiredError && !showMaximumAttemptsError && (
                                <p className="text-error-200">The activation code you entered is either incorrect or expired. Please try again or click "Resend code" to receive a new one.</p>
                            )}
                            {showMaximumAttemptsError && (
                                <div>
                                    <p className="mb-none text-error-200">You have reached the maximum amount of attempts allowed.</p>
                                    <p className="mb-xsm text-error-200">Please select "Resend code" if you need a new code.</p>
                                </div>
                            )}
                            {showUnknownError && (
                                <p className="text-error-200">An unexpected error occurred, please try again.</p>
                            )}
                            <Button
                                className="mt-xxs"
                                name="verify-email-verification-modal"
                                onClick={confirmVerificationCode}
                                variant="primary"
                                label="Verify code"
                                disabled={(verificationCodes.join("").length !== numOfCodeInputs) || showMaximumAttemptsError}
                                loading={isVerificationCodeLoading}
                            />
                            <Button
                                className=""
                                name="resend-email-verification-modal"
                                variant="link"
                                label="Resend code"
                                loading={isEmailLoading}
                                onClick={() => resendEmail()}
                            />
                            <div className="flex items-center mt-2">
                                <p className="float-left mb-0">Need to use different email?</p>
                                <Button
                                    name="change-email-address-verification-modal"
                                    variant="link"
                                    id="change-email-address-verification-modal"
                                    label="Change my email"
                                    className="underline"
                                    onClick={() => {
                                        router.push(Routes.accountInformationChangeEmail())
                                        onDismiss()
                                    }}
                                />
                            </div>{" "}
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default EmailValidationPopup
