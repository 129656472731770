/**
 * Error State
 * -----------------------------------------------------------------------------
 */
import { useState, useEffect } from "react"
import { Button, EventCard, Pictogram } from "elements"
import http from "utils/http"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly type: string
    readonly location: string
}
/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ErrorDisplay: React.FC<Props> = (props: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [errorFragment, setErrorFragment] = useState<any>({})
    const [loading, setLoading] = useState(true)

    /**
     * Fetch ways to pay fragment
     */
    const getFragment = async (fragmentId, setFragment) => {
        try {
            const config = { params: { id: fragmentId } }
            const {data, error} = await http.get("/api/managed-content", config)

            if (data && data.length > 0) {
                setFragment(data[0].data)
                setLoading(false)
            }

            if(error) {
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            return null
        }
    }

    const message =
        props.type === "errorWithoutCTA"
            ? `We're sorry, we're having trouble displaying your ${props.location} right now. Please try again later.`
            : `We're sorry, we're having trouble displaying your ${props.location} right now. Please contact customer support or try again later.`
    useEffect(() => {
        // Pull from fragment once JSON schema is used to a create fragment
        if(props.type === "errorWithCTA") {
            getFragment(
                "kPdic6sQRcqJmg56JZH4XQ",
                setErrorFragment,
            )
        } else{
            getFragment("eVhk8Zy4KgUTqdTBPqqRQm", setErrorFragment)
        }
    }, [])

    const containsErrorFragment = Object.keys(
        errorFragment,
    ).length
    
    return (
        <>
            {loading && (
                <ErrorDisplayShimmer />
            )}
            {!loading && !containsErrorFragment && (
                <div className={`bg-gray-25 dark:bg-dark-200 rounded-sm py-md px-lg`}>
                    <div className="text-center">
                    <Pictogram
                        regence="reg_alert_gray"
                        asuris="ahs_alert"
                        bridgespan="bsh_alert"
                        className="h-xxl mx-auto"
                    />
                    <p className="mb-none text-gray-200 font-md mt-xs" tabIndex={0}>
                        {message}
                    </p>
                    {props.type === "errorWithCTA" && (
                        <Button
                            name="see-support-options-link"
                            label="See support options"
                            size="medium"
                            href="/support/"
                            variant= "link"
                        />
                    )}
                </div>
                </div>
            )}
            {props.type === "errorWithoutCTA" &&
                Boolean(containsErrorFragment) && (
                <div className={`bg-gray-25 dark:bg-dark-200 rounded-sm py-md px-lg`}>
                    <div className="text-center">
                    <Pictogram
                        className="h-xxl mx-auto"
                        regence={
                            errorFragment.content[0].icon
                        }
                        asuris={errorFragment.content[0].icon}
                        bridgespan={
                            errorFragment.content[0].icon
                        }
                    />
                    <p className="mb-none text-gray-200 font-md mt-xs" tabIndex={0}>
                        {errorFragment.content[0].description.replace(
                            "{{location}}",
                            props.location,
                        )}
                    </p>
                </div>
                </div>
            )}
            {props?.type === "errorWithCTA" &&
                Boolean(containsErrorFragment) && (
                <div className={`bg-gray-25 dark:bg-dark-200 rounded-sm py-md px-lg`}>
                    <div className="text-center">
                    <Pictogram
                        className="h-xxl mx-auto"
                        regence={errorFragment.content[0].icon}
                        asuris={errorFragment.content[0].icon}
                        bridgespan={
                            errorFragment.content[0].icon
                        }
                    />
                    <p className="mb-none text-gray-200 font-md mt-xs" tabIndex={0}>
                        {errorFragment.content[0].description.replace(
                            "{{location}}",
                            props.location,
                        )}
                    </p>
                    <Button
                        name="see-support-options-link"
                        label={
                            errorFragment.content[0].link?.label
                        }
                        size="medium"
                        href={errorFragment.content[0].link?.url}
                        variant= "link"
                    />
                </div>
                </div>
            )}
        </>
    )
}

/**
 * Subcomponent:ErrorDisplay Shimmer
 * -----------------------------------------------------------------------------
 */
const ErrorDisplayShimmer: React.FC = () => {
    return (
        <EventCard
            name="error-display-shimmer-card"
            icon="rx"
            title="Loading..."
            status={{ label: "Loading" }}
            shimmer={true}
            className="mb-xs"
        />
    )
}

/**
 * Export Component
 * -----------------------------------------------------------------------------
 */
export default ErrorDisplay
